var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import CircularProgress from '@mui/material/CircularProgress';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import React from 'react';
import { Navigate, defer, useLoaderData, Await } from 'react-router-dom';
import { showToast } from 'components/Shared/Toast';
import { SessionStorageKey } from 'lib/constants';
import { createAndStoreAmazonToken } from 'lib/firebase';
var AMZ_AUTH_ERROR = {
    invalid_request: 'The request is missing a required parameter, has an invalid value, or is otherwise improperly formed.',
    unauthorized_client: 'The client is not authorized to request an authorization code.',
    access_denied: 'The resource owner or authorization server denied this request.',
    unsupported_response_type: 'The request specified an unsupported response type.',
    invalid_scope: "This app hasn't been verified to access",
    server_error: 'The authorization server encountered an unexpected error.',
    temporarily_unavailable: 'The authorization server is currently unavailable due to a temporary overload or scheduled maintenance',
};
var clearStorage = function () {
    sessionStorage.removeItem(SessionStorageKey.accountNickname);
    sessionStorage.removeItem(SessionStorageKey.oauthState);
};
var readParams = function (params) {
    var result = {
        error: params.get('error'),
        state: params.get('state'),
        oauthCode: params.get('spapi_oauth_code'),
        sellingPartnerId: params.get('selling_partner_id'),
        accountNickname: sessionStorage.getItem(SessionStorageKey.accountNickname),
        oauthState: sessionStorage.getItem(SessionStorageKey.oauthState),
    };
    return TE.right(result);
};
var ensureAmazonCallWasSuccessful = TE.filterOrElse(function (params) { return !params.error; }, function (params) { var _a; return AMZ_AUTH_ERROR[(_a = params.error) !== null && _a !== void 0 ? _a : ''] || 'There is an error with the callback'; });
var ensureOauthStateMatchesExpected = TE.filterOrElse(function (params) { return !!params.state && params.state == params.oauthState; }, function () { return 'OAuth error: State mismatch.'; });
var ensureInputIsPresent = TE.filterOrElse(function (params) { return !!(params.sellingPartnerId && params.oauthCode && params.accountNickname); }, function () { return 'Unexpected error occurred. Please try again later'; });
var createHttpCallParams = TE.map(function (params) { return ({
    oauthCode: params.oauthCode,
    sellingPartnerId: params.sellingPartnerId,
    accountNickname: params.accountNickname,
}); });
var tryToCreateAndStoreAmazonToken = TE.chain(function (params) {
    return TE.tryCatch(function () { return createAndStoreAmazonToken(params); }, function (reason) {
        return (reason === null || reason === void 0 ? void 0 : reason.message) || 'Something went wrong calling the function';
    });
});
var onSuccessRedirectToDashboard = TE.map(function () {
    return {
        redirectUrl: 'dashboard',
        msg: 'Channel has been added successfully',
        msgType: 'success',
    };
});
var onFailureGoToChannels = TE.getOrElse(function (msg) {
    return T.of({
        redirectUrl: 'channels',
        msg: msg,
        msgType: 'error',
    });
});
/**
 * Uses the passed arguments to call the HTTP function to create and store the AZ token in the DB.
 * @param params required arguments passed to as part of the request
 */
export var loader = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var url, createTokenTask, result;
    var request = _b.request;
    return __generator(this, function (_c) {
        url = new URL(request.url);
        createTokenTask = pipe(url.searchParams, readParams, ensureAmazonCallWasSuccessful, ensureOauthStateMatchesExpected, ensureInputIsPresent, createHttpCallParams, tryToCreateAndStoreAmazonToken, onSuccessRedirectToDashboard, onFailureGoToChannels);
        result = createTokenTask();
        return [2 /*return*/, defer({ whereToGo: result })];
    });
}); };
var AmazonCallback = function () {
    var data = useLoaderData();
    return (_jsx(React.Suspense, { fallback: _jsx(CircularProgress, {}), children: _jsx(Await, { resolve: data.whereToGo, errorElement: _jsx("p", { children: "Error redirecting from Amazon auth" }), children: function (whereToGo) {
                clearStorage();
                showToast(whereToGo.msg, whereToGo.msgType);
                return _jsx(Navigate, { to: "/".concat(whereToGo.redirectUrl), replace: true });
            } }) }));
};
export default AmazonCallback;
