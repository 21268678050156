import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Toolbar from '@mui/material/Toolbar';
import Image from 'components/Shared/Image';
import { MAILTO_URL } from 'lib/constants';
import { HeaderButton, HeaderContainer, NavigationWrapper, MenuItem } from './style';
var menu = {
    'Our Services': '#features',
    'How it Works?': '#how-it-works',
    'Contact Us': MAILTO_URL,
};
export default function Header() {
    return (_jsx(HeaderContainer, { position: "static", children: _jsxs(Toolbar, { sx: { display: 'flex', justifyContent: 'space-between' }, children: [_jsx(NavigationWrapper, { children: _jsx(Image, { src: "/images/logo.svg", alt: "logo", "data-testid": "logo" }) }), _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }, children: [Object.entries(menu).map(function (pair) { return (_jsx(MenuItem, { href: pair[1], children: pair[0] }, pair[1])); }), _jsx(HeaderButton, { to: "/sign-in", children: "Sign In" }), _jsx(HeaderButton, { to: "/sign-up", children: "Sign Up" })] })] }) }));
}
