import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes';
import './App.css';
if (process.env.REACT_APP_GA4_MEASURE_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA4_MEASURE_ID);
}
var App = function () {
    return (_jsxs(_Fragment, { children: [_jsx(ToastContainer, {}), _jsx(AppRoutes, {})] }));
};
export default App;
