import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { Link } from 'react-router-dom';
import Image from 'components/Shared/Image';
import PopOverMenu from 'components/Shared/Popover';
import ProfilePage from 'pages/Profile';
import { UserInfoContext } from '../../routes/ProtectedRoute';
import { DisplayName } from '../Shared/Popover/style';
import { HeaderContainer, Navigation, NavigationWrapper } from './style';
var menu = {
    Dashboard: '/dashboard',
    Listings: '/listing',
    Channels: '/channels',
};
export default function Header() {
    var _a = React.useState(null), anchorElNav = _a[0], setAnchorElNav = _a[1];
    var handleOpenNavMenu = function (event) {
        setAnchorElNav(event.currentTarget);
    };
    var handleCloseNavMenu = function () {
        setAnchorElNav(null);
    };
    var userInfo = React.useContext(UserInfoContext);
    var profileMenu = function () { return _jsx(ProfilePage, {}); };
    return (_jsx(HeaderContainer, { position: "static", children: _jsxs(Toolbar, { children: [_jsx(NavigationWrapper, { children: _jsx(Image, { src: "/images/logo.svg", alt: "logo", "data-testid": "logo" }) }), _jsxs(Box, { sx: { flexGrow: 1, display: { xs: 'flex', md: 'none' } }, children: [_jsx(IconButton, { size: "large", "aria-label": "menu bar", "aria-controls": "menu-appbar", "aria-haspopup": "true", onClick: handleOpenNavMenu, color: "inherit", children: _jsx(MenuIcon, {}) }), _jsx(Menu, { id: "menu-appbar", anchorEl: anchorElNav, anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            }, keepMounted: true, transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            }, open: Boolean(anchorElNav), onClose: handleCloseNavMenu, sx: {
                                display: { xs: 'block', md: 'none' },
                            }, children: Object.entries(menu).map(function (page) { return (_jsx(MenuItem, { onClick: handleCloseNavMenu, children: _jsx(Link, { to: page[1], children: _jsx(Navigation, { children: page[0] }) }, page[1]) }, page[1])); }) })] }), _jsx(Box, { sx: { flexGrow: 1, display: { xs: 'none', md: 'flex' } }, children: Object.entries(menu).map(function (page) { return (_jsx(Link, { to: page[1], children: _jsx(Navigation, { children: page[0] }) }, page[1])); }) }), _jsx(Box, { sx: { flexGrow: 1 } }), _jsx(Box, { sx: { display: { xs: 'none', sm: 'block' } }, children: _jsx(DisplayName, { children: userInfo === null || userInfo === void 0 ? void 0 : userInfo.companyName }) }), _jsx(Box, { children: _jsx(IconButton, { size: "large", children: _jsx(PopOverMenu, { id: "test", icon: "/images/user.svg", renderMenu: profileMenu }) }) })] }) }));
}
