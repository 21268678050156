export var BreakPoints;
(function (BreakPoints) {
    BreakPoints["SM"] = "@media screen and (max-width: 575px)";
    BreakPoints["MD"] = "@media screen and (max-width: 767px)";
    BreakPoints["LG"] = "@media screen and (max-width: 1023px)";
    BreakPoints["XL"] = "@media screen and (max-width: 1279px)";
})(BreakPoints || (BreakPoints = {}));
export var TRIAL_DAYS = 30;
export var SessionStorageKey;
(function (SessionStorageKey) {
    SessionStorageKey["oauthState"] = "oauth-state";
    SessionStorageKey["accountNickname"] = "account-nick-name";
})(SessionStorageKey || (SessionStorageKey = {}));
export var SUPPORT_EMAIL = 'info@teamdevbees.com';
export var MAILTO_URL = "mailto:".concat(SUPPORT_EMAIL);
