var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FirebaseError } from 'firebase/app';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import GoogleSignInPopup from 'components/Auth/SignInWithGoogle';
import { Container, LeftBox, RightBox, Heading, Text, Wrapper, Form, TwoColumn, } from 'components/Auth/styles';
import Button from 'components/Shared/Button';
import { InputText, InputPassword, CheckBox } from 'components/Shared/FormikFields';
import Image from 'components/Shared/Image';
import { showErrorToast } from 'components/Shared/Toast';
import { auth } from 'lib/firebase';
import { AUTH_SIGN_IN_ERROR } from 'lib/firebase/errors';
import { commonSignIn } from '../../routes';
var validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('This field is required'),
    password: Yup.string().required('This field is required'),
});
var SignIn = function () {
    var navigate = useNavigate();
    var state = useLocation().state;
    var onSubmit = useCallback(function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var errorMsg, err_1;
        var email = _b.email, password = _b.password, rememberMe = _b.rememberMe;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    errorMsg = function (err) {
                        return err instanceof FirebaseError
                            ? AUTH_SIGN_IN_ERROR[err === null || err === void 0 ? void 0 : err.code] || (err === null || err === void 0 ? void 0 : err.message)
                            : err.message;
                    };
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 5, , 6]);
                    if (!rememberMe) return [3 /*break*/, 3];
                    return [4 /*yield*/, setPersistence(auth, browserLocalPersistence)];
                case 2:
                    _c.sent();
                    _c.label = 3;
                case 3: return [4 /*yield*/, commonSignIn(navigate, function () { return signInWithEmailAndPassword(auth, email, password); }, errorMsg, state)];
                case 4:
                    _c.sent();
                    return [3 /*break*/, 6];
                case 5:
                    err_1 = _c.sent();
                    showErrorToast(errorMsg(err_1));
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: '',
            password: '',
            rememberMe: false,
        },
        onSubmit: onSubmit,
    });
    return (_jsxs(Container, { RFDirection: "column-reverse", children: [_jsx(LeftBox, { children: _jsxs(Wrapper, { children: [_jsx(Heading, { textAlign: "left", children: "Login" }), _jsx(FormikProvider, { value: formik, children: _jsxs(Form, { onSubmit: formik.handleSubmit, noValidate: true, children: [_jsx(InputText, { name: "email", label: "Email" }), _jsx(InputPassword, { name: "password", label: "Password" }), _jsxs(TwoColumn, { children: [_jsx(CheckBox, { name: "rememberMe", label: "Remember Me" }), _jsx(Link, { to: "/forgot-password", children: "Forgot Password" })] }), _jsx(Button, { type: "submit", children: "Log in" })] }) }), _jsxs(Text, { children: ["Don\u2019t have an account? ", _jsx(Link, { to: "/sign-up", children: "Sign up" })] }), _jsx(GoogleSignInPopup, { heading: "Or Log in with Email", buttonText: "Log in With Google" })] }) }), _jsx(RightBox, { children: _jsx(Image, { alt: "free trial", src: 'images/free-trial.png' }) })] }));
};
export default SignIn;
