var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
var xl = function () { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 30px;\n  border-radius: 8px;\n  padding: 12px;\n  width: 433px;\n"], ["\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 30px;\n  border-radius: 8px;\n  padding: 12px;\n  width: 433px;\n"]))); };
var md = function () { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 24px;\n  border-radius: 8px;\n  padding: 16px 24px;\n"], ["\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 24px;\n  border-radius: 8px;\n  padding: 16px 24px;\n"]))); };
var sm = function () { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  border-radius: 8px;\n  padding: 16px 60px;\n"], ["\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  border-radius: 8px;\n  padding: 16px 60px;\n"]))); };
var xsm = function () { return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 500;\n  font-size: 15px;\n  line-height: 22px;\n  border-radius: 8px;\n  padding: 8px 40px;\n"], ["\n  font-weight: 500;\n  font-size: 15px;\n  line-height: 22px;\n  border-radius: 8px;\n  padding: 8px 40px;\n"]))); };
var getSize = function (_a) {
    var size = _a.size;
    switch (size) {
        case 'xsm':
            return xsm;
        case 'sm':
            return sm;
        case 'md':
            return md;
        default:
            return xl;
    }
};
export var StyledButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: #023729;\n  opacity: ", ";\n  font-family: 'PoppinsMedium', serif;\n  font-style: normal;\n  white-space: nowrap;\n  color: #ffffff;\n  margin-top: 10px;\n  box-shadow: none;\n  outline: none;\n  border: none;\n  ", ";\n  &:hover {\n    opacity: 1;\n    cursor: ", ";\n  }\n"], ["\n  background: #023729;\n  opacity: ", ";\n  font-family: 'PoppinsMedium', serif;\n  font-style: normal;\n  white-space: nowrap;\n  color: #ffffff;\n  margin-top: 10px;\n  box-shadow: none;\n  outline: none;\n  border: none;\n  ", ";\n  &:hover {\n    opacity: 1;\n    cursor: ", ";\n  }\n"])), function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 0.5 : 1);
}, getSize, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 'not-allowed' : 'pointer');
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
