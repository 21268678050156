var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
export var InputText = function (_a) {
    var label = _a.label, props = __rest(_a, ["label"]);
    var _b = useField(props), field = _b[0], meta = _b[1];
    return (_jsx(TextField, { name: props.name, label: label, placeholder: props.placeholder, variant: "standard", fullWidth: true, margin: "normal", onBlur: field.onBlur, onChange: field.onChange, value: field.value, error: !!meta.error && meta.touched, helperText: meta.touched && meta.error, disabled: props.disabled }));
};
