import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Header from 'components/Header/Header';
import AboutUsDialog from 'components/Shared/AboutUsDialog/AboutUsDialog';
import Image from 'components/Shared/Image';
import PrivacyPolicyDialog from 'components/Shared/PrivacyPolicyDialog/PrivacyPolicyDialog';
import TermsConditionsDialog from 'components/Shared/TermsConditionsDialog/TermsConditionsDialog';
import { MAILTO_URL, SUPPORT_EMAIL } from 'lib/constants';
import { features, placeholder } from './content';
import { ChannelWrapper, Container, Copyrights, Description, Divider, FeatureCard, FeatureContainer, FeatureContent, FeatureImageWrapper, FeatureTitle, FeatureWrapper, FooterItemsWrapper, FooterLinkContainer, FooterLinks, FooterWrapper, HIWContainer, HIWDownArrow, HIWItem, HIWItemWrapper, HIWItems, HIWTip, HIWWrapper, Line, MailTo, SignupButton, Title, HIWTitle, FooterLink, } from './styles';
var TipLeftAlign = '40px';
var TipRightAlign = '-25px';
var featuresSlice = function (start, end) { return (_jsx(FeatureWrapper, { children: features.slice(start, end).map(function (ft) { return (_jsxs(FeatureCard, { children: [_jsx(FeatureTitle, { children: _jsx(Title, { lineHeight: "30px", fontSize: "20px", children: ft.title }) }), _jsx(FeatureContent, { children: _jsx(Description, { lineHeight: "30px", fontSize: "16px", children: ft.description }) })] }, ft.title)); }) })); };
var FeatureSection = function () { return (_jsxs(_Fragment, { children: [_jsx(ChannelWrapper, { id: "features", children: _jsx(Title, { children: "Our Services" }) }), _jsxs(FeatureContainer, { children: [featuresSlice(0, 2), _jsx(FeatureImageWrapper, { children: _jsx(Image, { src: "images/feature.png" }) }), featuresSlice(2, 4)] }), _jsx(FeatureCard, { children: _jsx(FeatureContent, { children: _jsx(Description, { lineHeight: "30px", fontSize: "16px", children: "Our application utilizes smart algorithms to analyze a merchant's product information, history, and performance, including analytics such as page views and sales, to provide data-driven price suggestions, advanced dashboards and reports. By using our app, merchants can make informed decisions that optimize sales and maximize profits." }) }) })] })); };
var HIW = function () { return (_jsx(HIWContainer, { id: "how-it-works", children: _jsxs(HIWWrapper, { children: [_jsx(HIWTitle, { children: "How it Works?" }), _jsxs(HIWItems, { children: [_jsxs(HIWItemWrapper, { children: [_jsx(HIWTip, { alignRight: TipRightAlign, children: _jsx(Image, { src: "images/message.svg" }) }), _jsxs(HIWItem, { margin: "0 0 0 50px", children: [_jsx("p", { children: "Sign-up with Pro Repricer" }), _jsx(Image, { src: "images/arrow-square-left.svg" })] })] }), _jsx(HIWItemWrapper, { children: _jsx(Image, { src: "images/line-arrow.svg" }) }), _jsxs(HIWItemWrapper, { children: [_jsx(HIWTip, { alignRight: TipRightAlign, children: _jsx(Image, { src: "images/message.svg" }) }), _jsxs(HIWItem, { margin: "0 0 0 50px", children: [_jsx("p", { children: "Connect your Amazon selling account" }), _jsx(Image, { src: "images/arrow-square-left.svg" })] })] })] }), _jsx(HIWDownArrow, { children: _jsx(Image, { src: "images/down-arrow.svg" }) }), _jsxs(HIWItems, { children: [_jsxs(HIWItemWrapper, { children: [_jsxs(HIWItem, { margin: "0 50px 0 0px", children: [_jsx("p", { children: "Accept or reject the price suggestions or enroll in automatic pricing updates" }), _jsx(Image, { src: "images/arrow-square-left.svg" })] }), _jsx(HIWTip, { alignRight: TipLeftAlign, children: _jsx(Image, { src: "images/message.svg" }) })] }), _jsx(HIWItemWrapper, { children: _jsx(Image, { src: "images/left-line-arrow.svg" }) }), _jsxs(HIWItemWrapper, { children: [_jsxs(HIWItem, { margin: "0 50px 0 0px", children: [_jsx("p", { children: "Pro Repricer fetches your data and runs its algorithms to suggest new prices" }), _jsx(Image, { src: "images/arrow-square-left.svg" })] }), _jsx(HIWTip, { alignRight: TipLeftAlign, children: _jsx(Image, { src: "images/message.svg" }) })] })] })] }) })); };
var Footer = function () { return (_jsx(FooterWrapper, { id: "contact", children: _jsx(HIWWrapper, { children: _jsxs(FooterItemsWrapper, { children: [_jsxs(FooterLinkContainer, { children: [_jsxs(FooterLinks, { children: [_jsx(FooterLink, { text: 'Privacy Policy', children: _jsx(PrivacyPolicyDialog, {}) }), _jsx(FooterLink, { text: 'Terms & Conditions', children: _jsx(TermsConditionsDialog, {}) }), _jsx(FooterLink, { text: 'About us', children: _jsx(AboutUsDialog, {}) })] }), _jsxs(MailTo, { href: MAILTO_URL, children: ["Contact us: ", SUPPORT_EMAIL, " / +1 (639) 997-5307"] })] }), _jsx("hr", {}), _jsx(FooterLinkContainer, { children: _jsx(Copyrights, { children: "\u00A9 2022 ProRepricer. All rights reserved." }) })] }) }) })); };
var HomePage = function () {
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsxs(Container, { children: [_jsxs(Divider, { children: [_jsxs("div", { children: [_jsx(Title, { children: "Pro Repricer" }), _jsx(Line, { src: "images/line.svg" }), _jsx(Description, { style: { paddingRight: '30px' }, children: placeholder }), _jsxs(SignupButton, { to: "sign-up", children: ["Sign Up for Free", _jsx(Image, { src: "images/redirect-arrow.svg" })] })] }), _jsx("div", { children: _jsx(Image, { src: "images/landing.png" }) })] }), _jsx(FeatureSection, {})] }), _jsx(HIW, {}), _jsx(Footer, {})] }));
};
export default HomePage;
