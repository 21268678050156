import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Popover from '@mui/material/Popover';
import React, { useState } from 'react';
import Image from 'components/Shared/Image';
var PopOverMenu = function (props) {
    var id = props.id, icon = props.icon, renderMenu = props.renderMenu;
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    return (_jsxs(_Fragment, { children: [_jsx("div", { "aria-hidden": "true", "data-testid": "popover", onClick: handleClick, children: _jsx(Image, { src: icon }) }), _jsx(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, "data-testid": "popoverMenu", anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }, children: renderMenu() })] }));
};
export default PopOverMenu;
