var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';
export var CheckBox = function (_a) {
    var label = _a.label, props = __rest(_a, ["label"]);
    var _b = useField(props), field = _b[0], meta = _b[1];
    return (_jsxs(FormControl, { error: !!meta.error, children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { color: "default", name: props.name, onChange: field.onChange, value: field.value, checked: !!field.value, sx: {
                        color: meta.touched && meta.error ? '#DC1515' : '#8d9091',
                    } }), label: label }), meta.touched && _jsx(FormHelperText, { children: meta.error })] }));
};
