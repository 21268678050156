var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Image from 'components/Shared/Image';
export var Table = styled.table(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: Poppins, sans-serif;\n  border-collapse: collapse;\n  width: 100%;\n"], ["\n  font-family: Poppins, sans-serif;\n  border-collapse: collapse;\n  width: 100%;\n"])));
export var Header = styled.thead(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 5px;\n"], ["\n  border-radius: 5px;\n"])));
export var TableHeader = styled.th(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: ", ";\n  height: 48px;\n  background: #f1f1f1;\n  text-align: center;\n  color: #858582;\n  font-family: Poppins, sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 10px;\n  line-height: 15px;\n  padding: 6px 18px 15px;\n  border-bottom: 8px solid #e1e1e1;\n\n  &.itemName {\n    width: 30%;\n  }\n  &.pop-over {\n    width: 30px;\n  }\n"], ["\n  width: ", ";\n  height: 48px;\n  background: #f1f1f1;\n  text-align: center;\n  color: #858582;\n  font-family: Poppins, sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 10px;\n  line-height: 15px;\n  padding: 6px 18px 15px;\n  border-bottom: 8px solid #e1e1e1;\n\n  &.itemName {\n    width: 30%;\n  }\n  &.pop-over {\n    width: 30px;\n  }\n"])), function (props) { return "".concat(props.width, "px") || '162px'; });
export var Cell = styled.td(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-align: center;\n  padding: 8px 24px;\n  gap: 4px;\n  height: 54px;\n  font-family: 'Poppins', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 18px;\n  color: #425663;\n  background: #ffffff;\n\n  &.latestSuggestion {\n    white-space: nowrap;\n    text-align: right;\n  }\n\n  &.price {\n    white-space: nowrap;\n    text-align: right;\n  }\n\n  &.itemName {\n    text-align: left;\n  }\n  &.latestSuggestion .grayText {\n    color: #c7c7c6;\n  }\n"], ["\n  text-align: center;\n  padding: 8px 24px;\n  gap: 4px;\n  height: 54px;\n  font-family: 'Poppins', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 18px;\n  color: #425663;\n  background: #ffffff;\n\n  &.latestSuggestion {\n    white-space: nowrap;\n    text-align: right;\n  }\n\n  &.price {\n    white-space: nowrap;\n    text-align: right;\n  }\n\n  &.itemName {\n    text-align: left;\n  }\n  &.latestSuggestion .grayText {\n    color: #c7c7c6;\n  }\n"])));
export var Row = styled.tr(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: #ffffff;\n  min-height: 54px;\n  border-bottom: 4px solid #e1e1e1;\n  border-radius: 5px;\n"], ["\n  background: #ffffff;\n  min-height: 54px;\n  border-bottom: 4px solid #e1e1e1;\n  border-radius: 5px;\n"])));
export var PopoverBox = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 263px;\n  background: #ffffff;\n  box-shadow: -3px 4px 12px rgba(0, 0, 0, 0.12);\n  border-radius: 5px;\n"], ["\n  width: 263px;\n  background: #ffffff;\n  box-shadow: -3px 4px 12px rgba(0, 0, 0, 0.12);\n  border-radius: 5px;\n"])));
export var PopoverMenuItem = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: #fafaf7;\n  border-radius: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 18px 24px;\n"], ["\n  background: #fafaf7;\n  border-radius: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 18px 24px;\n"])));
export var SuggestedPrice = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
export var AcceptedPrice = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  cursor: help;\n  font-weight: bolder;\n"], ["\n  cursor: help;\n  font-weight: bolder;\n"])));
export var AcceptPriceImage = styled(Image)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  cursor: pointer;\n  margin-left: 10px;\n  position: absolute;\n  width: 20px;\n"], ["\n  cursor: pointer;\n  margin-left: 10px;\n  position: absolute;\n  width: 20px;\n"])));
export var Tooltip = styled(function (props) { return (_jsx(MuiTooltip, __assign({ classes: { popper: props.className } }, props))); })(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  .", " {\n    background: #f2f2ef;\n    border-radius: 8px;\n    color: #858582;\n    padding: 8px;\n    font-size: 12px;\n    line-height: 18px;\n    .", " {\n      color: #f2f2ef;\n    }\n  }\n"], ["\n  .", " {\n    background: #f2f2ef;\n    border-radius: 8px;\n    color: #858582;\n    padding: 8px;\n    font-size: 12px;\n    line-height: 18px;\n    .", " {\n      color: #f2f2ef;\n    }\n  }\n"])), tooltipClasses.tooltip, tooltipClasses.arrow);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
