export var DISABLED_MARKETPLACE_DOMAINS = [
    'Australia',
    'Belgium',
    'Brazil',
    'Canada',
    'Egypt',
    'France',
    'Germany',
    'India',
    'Italy',
    'Japan',
    'Mexico',
    'Netherlands',
    'Poland',
    'Saudi Arabia',
    'Singapore',
    'Spain',
    'Sweden',
    'Turkey',
    'United Arab Emirates',
    'United Kingdom',
];
