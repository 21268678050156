var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getAuth } from 'firebase/auth';
import { pipe } from 'fp-ts/function';
import * as RoArray from 'fp-ts/ReadonlyArray';
import React, { useState } from 'react';
import { useLoaderData, useSearchParams, useRevalidator, useParams, useNavigate, } from 'react-router-dom';
import { ChannelType } from 'shared/src/typings/dbmodels';
import ListingTable from 'components/Listing/Table';
import Image from 'components/Shared/Image';
import { getVisibleListings, getVisibleListingsCount, acceptRepriceSuggestion, getChannels, } from 'lib/firebase/firestore/listing';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toast';
import * as ST from './style';
var toChannelsVM = function (channels) {
    return pipe(channels, RoArray.map(function (channel) { return ({
        id: channel.id,
        name: channel.accountNickname,
        icon: channel.channelType == ChannelType.Amazon ? '/images/amazon.svg' : '/images/eBay.svg',
    }); }));
};
/**
 * Loader (wrapper) that validates first the user is present
 * @param f Function to call if the user is present
 */
export var userLoaderFn = function (f) {
    return function (args) {
        var user = getAuth().currentUser;
        return user && f(user, args);
    };
};
var PAGE_SIZE = 50;
function paginationInfo(count, pageSize, currentPage) {
    var totalPages = Math.ceil(count / pageSize);
    var maxPages = 5;
    var startPage, endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    }
    else {
        // total pages more than max so calculate start and end pages
        var maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        var maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        }
        else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        }
        else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }
    return { totalPages: totalPages, startPage: startPage, endPage: endPage };
}
export var loader = userLoaderFn(function (user, args) { return __awaiter(void 0, void 0, Promise, function () {
    var channelId, channels, url, last, first, currentPage, count, _a, pagination;
    var _b;
    var _c, _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                channelId = (_c = args === null || args === void 0 ? void 0 : args.params) === null || _c === void 0 ? void 0 : _c.channelId;
                return [4 /*yield*/, getChannels(user.uid)];
            case 1:
                channels = _g.sent();
                if (!channelId) {
                    return [2 /*return*/, {
                            channels: channels,
                            count: 0,
                            currentPage: 1,
                            listings: [],
                            pagination: { endPage: 0, startPage: 0, totalPages: 0 },
                        }];
                }
                url = new URL((_d = args === null || args === void 0 ? void 0 : args.request) === null || _d === void 0 ? void 0 : _d.url);
                last = (_e = url.searchParams.get('last')) !== null && _e !== void 0 ? _e : undefined;
                first = (_f = url.searchParams.get('first')) !== null && _f !== void 0 ? _f : undefined;
                currentPage = parseInt(url.searchParams.get('page') || '1');
                if (!process.env.REACT_APP_ACCEPTANCE) return [3 /*break*/, 2];
                _a = 50;
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, getVisibleListingsCount(user.uid, channelId)];
            case 3:
                _a = _g.sent();
                _g.label = 4;
            case 4:
                count = _a;
                pagination = paginationInfo(count, PAGE_SIZE, currentPage);
                _b = {
                    channels: channels
                };
                return [4 /*yield*/, getVisibleListings(user.uid, channelId, PAGE_SIZE, first, last)];
            case 5: return [2 /*return*/, (_b.listings = _g.sent(),
                    _b.count = count,
                    _b.currentPage = currentPage,
                    _b.pagination = pagination,
                    _b)];
        }
    });
}); });
var ListingPage = function () {
    var _a;
    var _b = useSearchParams(), searchParams = _b[0], setSearchParams = _b[1];
    var channelId = useParams().channelId;
    var navigate = useNavigate();
    var revalidate = useRevalidator().revalidate;
    var _c = useLoaderData(), channels = _c.channels, listings = _c.listings, currentPage = _c.currentPage, pagination = _c.pagination;
    var _d = useState(channelId !== null && channelId !== void 0 ? channelId : (_a = channels[0]) === null || _a === void 0 ? void 0 : _a.id), selectedChannel = _d[0], setSelectedChannel = _d[1];
    var onPageChange = function (event, page) {
        if (![currentPage, currentPage - 1, currentPage + 1].includes(page)) {
            return;
        }
        searchParams.set('page', page.toString());
        if (page > currentPage) {
            searchParams.set('last', listings === null || listings === void 0 ? void 0 : listings[(listings === null || listings === void 0 ? void 0 : listings.length) - 1].listingId);
            searchParams.delete('first');
        }
        else {
            searchParams.set('first', listings === null || listings === void 0 ? void 0 : listings[0].listingId);
            searchParams.delete('last');
        }
        setSearchParams(searchParams);
    };
    var acceptReprice = function (listing) {
        var user = getAuth().currentUser;
        if (!user) {
            return;
        }
        acceptRepriceSuggestion(user.uid, channelId, listing)
            .then(function () {
            showSuccessToast('Suggest price has been accepted successfully');
            revalidate();
        })
            .catch(function (err) {
            showErrorToast(err);
        });
    };
    return (_jsxs(ST.Page, { children: [_jsxs(ST.HeadingContainer, { children: [_jsx("div", { children: _jsx("h3", { style: { margin: 0 }, children: "Products Detail" }) }), _jsx(ST.ChannelWrapper, { children: toChannelsVM(channels).map(function (channel) { return (_jsx(ST.ListingChannel, { isSelected: selectedChannel === (channel === null || channel === void 0 ? void 0 : channel.id), onClick: function () {
                                setSelectedChannel(channel.id);
                                navigate("/channels/".concat(channel.id));
                            }, "data-testid": "".concat(channel === null || channel === void 0 ? void 0 : channel.name, "-button"), "aria-hidden": "true", title: channel.name, children: _jsx(Image, { src: channel === null || channel === void 0 ? void 0 : channel.icon, alt: channel === null || channel === void 0 ? void 0 : channel.name, "data-testid": "".concat(channel === null || channel === void 0 ? void 0 : channel.name, "-logo") }) }, channel.name)); }) })] }), _jsxs(_Fragment, { children: [!listings && _jsx(ST.Loader, {}), (listings === null || listings === void 0 ? void 0 : listings.length) === 0 && (_jsx(ST.ListingNotFound, { children: "We are still working on loading the data. Please come back in a bit." })), listings && (listings === null || listings === void 0 ? void 0 : listings.length) > 0 && (_jsxs(_Fragment, { children: [_jsx(ListingTable, { products: listings, acceptSuggestion: acceptReprice }), _jsx(ST.ListingPagination, { count: pagination.totalPages, page: currentPage, onChange: onPageChange })] }))] })] }));
};
export default ListingPage;
