var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { BreakPoints } from 'lib/constants';
export var Heading = styled.h1(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: 'PoppinsSemiBold', serif;\n  text-align: center;\n  font-style: normal;\n  font-weight: 600;\n  font-size: ", ";\n  line-height: 39px;\n  color: #053f5c;\n  width: 100%;\n  margin: 0;\n  padding: 10px;\n  ", " {\n    font-size: 20px;\n    line-height: 24px;\n  }\n"], ["\n  font-family: 'PoppinsSemiBold', serif;\n  text-align: center;\n  font-style: normal;\n  font-weight: 600;\n  font-size: ", ";\n  line-height: 39px;\n  color: #053f5c;\n  width: 100%;\n  margin: 0;\n  padding: 10px;\n  ", " {\n    font-size: 20px;\n    line-height: 24px;\n  }\n"])), function (props) { return props.fontSize; }, BreakPoints.MD);
export var Redirect = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 10px;\n  text-decoration: none;\n  color: white;\n  border-radius: 5px;\n  text-align: center;\n  background: #023729;\n"], ["\n  padding: 10px;\n  text-decoration: none;\n  color: white;\n  border-radius: 5px;\n  text-align: center;\n  background: #023729;\n"])));
export var ErrorWrapper = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  min-height: 80vh;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  min-height: 80vh;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
