var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import * as STButton from '../../components/Shared/Button/styles';
import Image from '../../components/Shared/Image';
import { BreakPoints } from '../../lib/constants';
export var Logo = styled(Image)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 150px;\n  width: 150px;\n  background: #023729;\n  margin: 0 auto;\n  ", " {\n    height: 91px;\n    width: 91px;\n  }\n"], ["\n  height: 150px;\n  width: 150px;\n  background: #023729;\n  margin: 0 auto;\n  ", " {\n    height: 91px;\n    width: 91px;\n  }\n"])), BreakPoints.LG);
export var Button = styled(STButton.StyledButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 300px;\n  margin: 0 auto;\n"], ["\n  max-width: 300px;\n  margin: 0 auto;\n"])));
export var WelcomeImg = styled(Image)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 100%;\n  margin: 0 auto;\n"], ["\n  max-width: 100%;\n  margin: 0 auto;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
