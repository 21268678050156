var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { CircularProgress, lighten, Pagination } from '@mui/material';
export var HeadingContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-bottom: 40px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-bottom: 40px;\n"])));
export var Loader = styled(CircularProgress)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var ListingPagination = styled(Pagination)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 40px;\n  ul {\n    margin: auto;\n    li:not(:first-of-type):not(:last-of-type) {\n      button {\n        cursor: default;\n        pointer-events: none;\n      }\n    }\n    li:last-of-type,\n    li:first-of-type {\n      button {\n        cursor: pointer;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  margin-top: 40px;\n  ul {\n    margin: auto;\n    li:not(:first-of-type):not(:last-of-type) {\n      button {\n        cursor: default;\n        pointer-events: none;\n      }\n    }\n    li:last-of-type,\n    li:first-of-type {\n      button {\n        cursor: pointer;\n      }\n    }\n  }\n"])));
export var ListingNotFound = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 18px;\n  line-height: 30;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 18px;\n  line-height: 30;\n"])));
export var ChannelWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
export var ListingChannel = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  cursor: pointer;\n  display: flex;\n  height: 36px;\n  justify-content: center;\n  width: 75px;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  cursor: pointer;\n  display: flex;\n  height: 36px;\n  justify-content: center;\n  width: 75px;\n"])), function (props) { return (props.isSelected ? '#023729' : lighten('#023729', 0.8)); });
export var Page = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: #e1e1e1;\n  padding: 40px 24px;\n"], ["\n  background: #e1e1e1;\n  padding: 40px 24px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
// export const Pagination =styled.`
