var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
var IOSSwitch = styled(function (props) { return (_jsx(Switch, __assign({ focusVisibleClassName: ".Mui-focusVisible", disableRipple: true }, props))); })(function (_a) {
    var theme = _a.theme;
    return ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#023729',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#023729',
            borderRadius: '16.5px',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    });
});
var Toggle = function (props) {
    var onChange = props.onChange, value = props.value;
    return _jsx(IOSSwitch, { "data-testid": "switch", checked: value, onChange: onChange });
};
export default Toggle;
