var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useField } from 'formik';
export var SelectInput = function (_a) {
    var label = _a.label, options = _a.options, disabledOptions = _a.disabledOptions, props = __rest(_a, ["label", "options", "disabledOptions"]);
    var _b = useField(props), field = _b[0], meta = _b[1], helpers = _b[2];
    return (_jsxs(FormControl, { fullWidth: true, variant: "standard", margin: "normal", error: meta.touched && !!meta.error, children: [_jsx(InputLabel, { children: label }), _jsx(Select, { "data-testid": label, value: field.value, onChange: function (e) { return helpers.setValue(e.target.value); }, label: label, onOpen: function () { return helpers.setTouched(true); }, children: options.map(function (option) { return (_jsx(MenuItem, { value: option, disabled: disabledOptions === null || disabledOptions === void 0 ? void 0 : disabledOptions.includes(option), children: option }, option)); }) }), meta.touched && _jsx(FormHelperText, { children: meta.error })] }));
};
