import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import React, { useState } from 'react';
var InputPassword = function (_a) {
    var error = _a.error, _b = _a.label, label = _b === void 0 ? 'Password' : _b, _c = _a.name, name = _c === void 0 ? 'password' : _c, onBlur = _a.onBlur, onChange = _a.onChange, placeholder = _a.placeholder, value = _a.value;
    var _d = useState(false), showPassword = _d[0], setShowPassword = _d[1];
    return (_jsxs(FormControl, { variant: "standard", fullWidth: true, error: !!error, children: [_jsx(InputLabel, { htmlFor: name, children: label }), _jsx(Input, { id: name, name: name, type: showPassword ? 'text' : 'password', onChange: onChange, onBlur: onBlur, value: value, error: !!error, placeholder: placeholder, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return setShowPassword(!showPassword); }, onMouseDown: function (event) { return event.preventDefault(); }, children: showPassword ? (_jsx(VisibilityOffOutlinedIcon, { "data-testid": "VisibilityOff" })) : (_jsx(VisibilityOutlinedIcon, { "data-testid": "VisibilityOn" })) }) }) }), _jsx(FormHelperText, { "data-testid": "field-validation-error", children: error })] }));
};
export default InputPassword;
