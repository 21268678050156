import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { signInWithPopup } from 'firebase/auth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Image from 'components/Shared/Image';
import { auth, googleProvider } from 'lib/firebase';
import { findAuthErrorByMessage } from 'lib/firebase/errors';
import { commonSignIn } from '../../../routes';
import { GoogleAuthText, GoogleAuthWrapper, GoogleButton } from '../styles';
var GoogleSignInPopup = function (_a) {
    var heading = _a.heading, buttonText = _a.buttonText;
    var navigate = useNavigate();
    var signInWithGoogle = function () {
        return commonSignIn(navigate, function () { return signInWithPopup(auth, googleProvider); }, function (err) {
            return findAuthErrorByMessage(err, 'There is an error trying to Sign in, please retry later.');
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(GoogleAuthWrapper, { children: _jsx(GoogleAuthText, { children: heading }) }), _jsxs(GoogleButton, { type: "button", onClick: signInWithGoogle, children: [_jsx(Image, { src: "/images/google.svg" }), buttonText] })] }));
};
export default GoogleSignInPopup;
