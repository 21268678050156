var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { confirmPasswordReset } from 'firebase/auth';
import { FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import Button from 'components/Shared/Button';
import { InputPassword } from 'components/Shared/FormikFields';
import Image from 'components/Shared/Image';
import { showErrorToast } from 'components/Shared/Toast';
import { auth } from 'lib/firebase';
import { findErrorMessage } from 'lib/firebase/errors';
import { BackToSignInLink, Heading, Wrapper, Form, Container, LinkText, SubHeading, HeroSection, } from 'pages/ForgotPassword/styles';
YupPassword(Yup);
var validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('This field is required')
        .min(8, 'Password must be at least 8 characters')
        .minLowercase(1, 'Password must include a lowercase letter')
        .minUppercase(1, 'Password must include an uppercase letter')
        .minNumbers(1, 'Password must include a digit')
        .minSymbols(1, 'Password must include a special character (!@#$%^&*)'),
    confirmPassword: Yup.string()
        .required('This field is required')
        .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value;
    }),
});
var ResetSuccessful = function () {
    var navigate = useNavigate();
    return (_jsxs(_Fragment, { children: [_jsx(Heading, { children: "Password reset" }), _jsx(SubHeading, { children: "Your password has been successfully reset" }), _jsx(Button, { onClick: function () { return navigate('/sign-in'); }, type: "button", children: "Continue" })] }));
};
var ResetPasswordWidget = function (_a) {
    var formik = _a.formik;
    return (_jsxs(_Fragment, { children: [_jsx(Heading, { children: "Set new password" }), _jsx(SubHeading, { children: "Your new password must be different to previously used password" }), _jsx(FormikProvider, { value: formik, children: _jsxs(Form, { onSubmit: formik.handleSubmit, noValidate: true, children: [_jsx(InputPassword, { label: "Password", name: "password" }), _jsx(InputPassword, { label: "Confirm Password", name: "confirmPassword" }), _jsx(Button, { type: "submit", children: "Reset Password" })] }) }), _jsxs(BackToSignInLink, { to: "/sign-in", children: [_jsx(Image, { src: "/images/back.svg" }), _jsx(LinkText, { children: "Back to login" })] })] }));
};
var ResetPassword = function () {
    var _a = useState(false), pwdResetSuccess = _a[0], setPwdResetSuccess = _a[1];
    var state = useLocation().state;
    var onSubmit = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var code, err_1;
        var password = _b.password;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    code = state.oobCode;
                    if (!code) {
                        throw new Error('Invalid url');
                    }
                    return [4 /*yield*/, confirmPasswordReset(auth, code, password)];
                case 1:
                    _c.sent();
                    setPwdResetSuccess(true);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _c.sent();
                    showErrorToast(findErrorMessage(err_1, 'There is an error trying to reset the password, please retry in a bit.'));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        onSubmit: onSubmit,
    });
    return (_jsxs(_Fragment, { children: [_jsx(HeroSection, { children: _jsx(Image, { src: "/images/forgot-password.svg" }) }), _jsx(Container, { children: _jsx(Wrapper, { children: pwdResetSuccess ? _jsx(ResetSuccessful, {}) : _jsx(ResetPasswordWidget, { formik: formik }) }) })] }));
};
export default ResetPassword;
