var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { BreakPoints } from 'lib/constants';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  height: calc(100vh - 110px);\n  width: 100%;\n  align-items: center;\n  ", " {\n    height: auto;\n  }\n"], ["\n  display: flex;\n  height: calc(100vh - 110px);\n  width: 100%;\n  align-items: center;\n  ", " {\n    height: auto;\n  }\n"])), BreakPoints.MD);
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: #ffffff;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  padding: 20px;\n  border: 1px solid rgba(0, 0, 0, 0.2);\n  justify-content: space-around;\n  ", " {\n    max-width: 400px;\n  }\n  ", " {\n    max-width: 300px;\n  }\n"], ["\n  background: #ffffff;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  padding: 20px;\n  border: 1px solid rgba(0, 0, 0, 0.2);\n  justify-content: space-around;\n  ", " {\n    max-width: 400px;\n  }\n  ", " {\n    max-width: 300px;\n  }\n"])), BreakPoints.LG, BreakPoints.MD);
export var Item = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding-bottom: 10px;\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding-bottom: 10px;\n"])));
export var ItemIcon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 36px;\n  height: 36px;\n  background: #f9fafc;\n  border-radius: 4px;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n  margin-right: 10px;\n"], ["\n  width: 36px;\n  height: 36px;\n  background: #f9fafc;\n  border-radius: 4px;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n  margin-right: 10px;\n"])));
export var ItemContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: left;\n  h2 {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 30px;\n    color: #083b2e;\n    margin: 0 0 10px;\n  }\n  p {\n    margin: 0;\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 21px;\n    color: #8d9091;\n  }\n"], ["\n  text-align: left;\n  h2 {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 30px;\n    color: #083b2e;\n    margin: 0 0 10px;\n  }\n  p {\n    margin: 0;\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 21px;\n    color: #8d9091;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
