var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getAuth } from 'firebase/auth';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import ErrorBoundary from '../components/ErrorBoundary';
export var Unregistered = { _tag: 'unregistered', url: 'sign-in' };
export var Unverified = { _tag: 'unverified', url: 'email-not-verified' };
export var StoreInformationPending = { _tag: 'incomplete', url: 'store-info' };
export var Subscribed = { _tag: 'trial', url: 'dashboard' };
function mkUserType(user, userInfo) {
    if (!user) {
        return Unregistered;
    }
    if (!user.emailVerified) {
        return Unverified;
    }
    if (!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.companyName)) {
        return StoreInformationPending;
    }
    return Subscribed;
}
export var UserInfoContext = React.createContext(null);
var PrivateRoute = function (_a) {
    var required = _a.required;
    var location = useLocation();
    var currentUser = getAuth().currentUser;
    var userInfo = React.useContext(UserInfoContext);
    var actual = mkUserType(currentUser, userInfo);
    if (actual != required) {
        return (_jsx(Navigate, { to: { pathname: actual.url }, replace: true, state: __assign(__assign({}, ((location === null || location === void 0 ? void 0 : location.state) && location.state)), { prevPath: location.pathname }) }));
    }
    return (_jsx(ErrorBoundary, { children: _jsx(Outlet, {}) }));
};
export default PrivateRoute;
