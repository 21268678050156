var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';
import Button from 'components/Shared/Button';
export var RepricerSwitch = styled(Switch)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &.MuiSwitch-sizeMedium {\n    padding: 5px;\n    .MuiSwitch-track {\n      border-radius: 13px;\n    }\n    .Mui-checked {\n      color: #053f5c;\n    }\n  }\n"], ["\n  &.MuiSwitch-sizeMedium {\n    padding: 5px;\n    .MuiSwitch-track {\n      border-radius: 13px;\n    }\n    .Mui-checked {\n      color: #053f5c;\n    }\n  }\n"])));
export var Heading = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 20px;\n  line-height: 30px;\n  color: #425663;\n  font-family: 'PoppinsSemiBold', serif;\n"], ["\n  font-size: 20px;\n  line-height: 30px;\n  color: #425663;\n  font-family: 'PoppinsSemiBold', serif;\n"])));
export var Page = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 40px;\n"], ["\n  padding: 40px;\n"])));
export var ChannelWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 226px;\n  height: auto;\n  background-color: #ffffff;\n  border-radius: 8px;\n  padding: 25px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"], ["\n  width: 226px;\n  height: auto;\n  background-color: #ffffff;\n  border-radius: 8px;\n  padding: 25px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n"])));
export var Text = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: 'PoppinsSemiBold', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  color: #053f5c;\n  margin: 35px 0 3px 0;\n"], ["\n  font-family: 'PoppinsSemiBold', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  color: #053f5c;\n  margin: 35px 0 3px 0;\n"])));
export var Percentage = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 15px;\n  color: #053f5c;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  margin-top: 3px;\n  svg {\n    &:hover {\n      cursor: pointer;\n    }\n  }\n"], ["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 15px;\n  color: #053f5c;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  margin-top: 3px;\n  svg {\n    &:hover {\n      cursor: pointer;\n    }\n  }\n"])));
export var Title = styled(Text)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  width: 100%;\n  margin: 0;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  width: 100%;\n  margin: 0;\n"])));
export var GoTo = styled(Link)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 7px;\n  font-family: 'PoppinsSemiBold', serif;\n  font-weight: 500;\n  font-size: 12px;\n  line-height: 18px;\n  color: #053f5c;\n  text-decoration: none;\n  margin-top: 30px;\n  text-transform: capitalize;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 7px;\n  font-family: 'PoppinsSemiBold', serif;\n  font-weight: 500;\n  font-size: 12px;\n  line-height: 18px;\n  color: #053f5c;\n  text-decoration: none;\n  margin-top: 30px;\n  text-transform: capitalize;\n"])));
export var ChannelsContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  margin-top: 2%;\n  align-items: flex-start;\n  width: 100%;\n  gap: 40px;\n  padding: 0;\n"], ["\n  display: flex;\n  flex-direction: row;\n  margin-top: 2%;\n  align-items: flex-start;\n  width: 100%;\n  gap: 40px;\n  padding: 0;\n"])));
export var ModalContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: #dde4e2;\n  border-radius: 8px;\n  left: 50%;\n  height: auto;\n  width: 384px;\n  padding: 32px;\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n"], ["\n  background: #dde4e2;\n  border-radius: 8px;\n  left: 50%;\n  height: auto;\n  width: 384px;\n  padding: 32px;\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n"])));
export var ModalTitle = styled(Text)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  margin: 0;\n  justify-content: space-between;\n  img {\n    &:hover {\n      cursor: pointer;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  margin: 0;\n  justify-content: space-between;\n  img {\n    &:hover {\n      cursor: pointer;\n    }\n  }\n"])));
export var ModalContent = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background: #ffffff;\n  border-radius: 4px;\n  margin-top: 25px;\n  padding: 32px;\n  height: auto;\n"], ["\n  background: #ffffff;\n  border-radius: 4px;\n  margin-top: 25px;\n  padding: 32px;\n  height: auto;\n"])));
export var CancelButton = styled(Button)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  background: #ffffff;\n  color: #1f1f1e;\n"], ["\n  background: #ffffff;\n  color: #1f1f1e;\n"])));
export var ModalAction = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  margin-top: 20px;\n  gap: 10px;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  margin-top: 20px;\n  gap: 10px;\n"])));
export var TwoColumn = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  gap: 20px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  gap: 20px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
