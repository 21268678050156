import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import { CloseButton } from './styles';
var TermsLabel = function (_a) {
    var children = _a.children, text = _a.text, className = _a.className;
    var _b = useState(false), showTerms = _b[0], setShowTerms = _b[1];
    var showModal = function (event) {
        event.preventDefault();
        setShowTerms(true);
    };
    var handleClose = function () {
        setShowTerms(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx("a", { "data-testid": "terms-label", tabIndex: 0, role: "button", onClick: function (event) { return showModal(event); }, onKeyDown: function (event) { return showModal(event); }, className: className, children: text }), _jsxs(Dialog, { open: showTerms, onClose: handleClose, "aria-labelledby": "Terms Of Service and Privacy Policy", "aria-describedby": "Terms Of Service and Privacy Policy", "data-testid": "terms-popup", children: [_jsx(DialogTitle, { children: text }), _jsx(DialogContent, { dividers: true, children: children }), _jsx(DialogActions, { children: _jsx(CloseButton, { size: "xsm", onClick: handleClose, children: "Close" }) })] })] }));
};
export default TermsLabel;
