import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getAuth, sendEmailVerification } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Heading, MessageContainer } from 'components/Auth/styles';
import Image from 'components/Shared/Image';
var EmailNotVerified = function () {
    var _a;
    var _b = useState(), message = _b[0], setMessage = _b[1];
    var state = useLocation().state;
    var user = getAuth().currentUser;
    var firstTimeMountRef = useRef(true);
    useEffect(function () {
        if (firstTimeMountRef.current && (state === null || state === void 0 ? void 0 : state.signUp)) {
            firstTimeMountRef.current = false;
            resendEmailVerification();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);
    var resendEmailVerification = function () {
        sendEmailVerification(user)
            .then(function () { return setMessage('Please check your emails, we just sent you a verification message'); })
            .catch(function (error) {
            console.log(error === null || error === void 0 ? void 0 : error.message);
            setMessage('Oops, there was an error sending the email verification, please try again later');
        });
    };
    return (_jsxs(MessageContainer, { flexDirection: "column", children: [_jsx(Image, { alt: "email not verified", src: "images/sign-up-success.png" }), _jsx(Heading, { width: "auto", children: "Verify your Email address" }), _jsx("span", { children: (state === null || state === void 0 ? void 0 : state.signUp) ? (_jsx(_Fragment, { children: "Your account has been created successfully" })) : (_jsxs(_Fragment, { children: [(_a = user === null || user === void 0 ? void 0 : user.displayName) !== null && _a !== void 0 ? _a : 'Hey', ", to start using ProRepricer, we need to verify your email", _jsx("a", { children: user === null || user === void 0 ? void 0 : user.email })] })) }), _jsx("br", {}), _jsx("span", { children: _jsx("a", { tabIndex: 0, role: "button", onClick: function () { return resendEmailVerification(); }, onKeyDown: function () { return resendEmailVerification(); }, children: "Resend Email" }) }), _jsx("br", {}), _jsx("span", { children: message })] }));
};
export default EmailNotVerified;
