export var placeholder = 'A quick and easy re-pricing solution to increase sales conversions and profits.';
export var features = [
    {
        title: 'Connect Amazon marketplace',
        description: 'Connect your Amazon marketplace account with Pro Repricer.',
        image: 'images/task-square.svg',
    },
    {
        title: 'Visualize all product listings together',
        description: 'Get a consolidated view of all your product listings.',
        image: 'images/task-square.svg',
    },
    {
        title: 'Receive re-pricing suggestions',
        description: 'Gain re-pricing suggestions based on Pro Repricer’s smart algorithm.',
        image: 'images/box-search.svg',
    },
    {
        title: 'Push price changes',
        description: 'Update price changes directly in your Amazon marketplace with just few clicks.',
        image: 'images/task-square.svg',
    },
];
export var channels = [
    {
        logo: 'images/amazon-channel.svg',
        name: 'Amazon',
        enabled: true,
    },
    {
        logo: 'images/eBay-logo.svg',
        name: 'eBay',
    },
    {
        logo: 'images/shopify.svg',
        name: 'Shopify',
    },
    {
        logo: 'images/walmart.svg',
        name: 'Walmart',
    },
];
export var aboutUs = [
    { description: placeholder, logo: 'images/user1.png', customerName: 'Test', stars: 1 },
    { description: placeholder, logo: 'images/user1.png', customerName: 'Test', stars: 2 },
    {
        description: placeholder,
        logo: 'images/amazon-channel.svg',
        customerName: 'Test',
        stars: 3,
    },
    { description: placeholder, logo: 'images/user1.png', customerName: 'Test', stars: 4 },
    { description: placeholder, logo: 'images/walmart.svg', customerName: 'Test', stars: 5 },
    {
        description: placeholder,
        logo: 'images/amazon-channel.svg',
        customerName: 'Test',
        stars: 1,
    },
    { description: placeholder, logo: 'images/user1.png', customerName: 'Test', stars: 2 },
];
