var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useFormik, useField, FormikProvider } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import Button from 'components/Shared/Button';
import Image from 'components/Shared/Image';
import { ModalContainer, ModalTitle, ModalContent, CancelButton, ModalAction, TwoColumn, } from './style';
var InputPercentage = function (_a) {
    var label = _a.label, props = __rest(_a, ["label"]);
    var _b = useField(props), field = _b[0], meta = _b[1];
    return (_jsx(TextField, { name: props.name, label: label, placeholder: props.placeholder, variant: "standard", size: "small", onBlur: field.onBlur, onChange: field.onChange, value: field.value, error: !!meta.error && meta.touched, helperText: meta.touched && meta.error, InputProps: {
            type: 'number',
            endAdornment: _jsx(InputAdornment, { position: "end", children: "%" }),
        } }));
};
var validationSchema = Yup.object().shape({
    maxPercentage: Yup.number()
        .typeError('Max percentage must be a number')
        .positive('Max percentage must be a positive number')
        .required('This field is required'),
    minPercentage: Yup.number()
        .typeError('Min percentage must be a number')
        .positive('Min percentage must be a positive number')
        .required('This field is required'),
});
var MinMaxModal = function (_a) {
    var _b, _c;
    var channel = _a.channel, onConfirm = _a.onConfirm, onCancel = _a.onCancel;
    var formik = useFormik({
        validationSchema: validationSchema,
        enableReinitialize: true,
        initialValues: {
            maxPercentage: (_b = channel === null || channel === void 0 ? void 0 : channel.maxPercentage) !== null && _b !== void 0 ? _b : 10,
            minPercentage: (_c = channel === null || channel === void 0 ? void 0 : channel.minPercentage) !== null && _c !== void 0 ? _c : 10,
        },
        onSubmit: onConfirm,
    });
    return (_jsx(Modal, { open: !!channel, "aria-labelledby": "Min Max Percentages", "aria-describedby": "Min Max Percentages", "data-testid": "min-max-percentages", children: _jsxs(ModalContainer, { children: [_jsxs(ModalTitle, { children: ["Set Min Max Percentages", _jsx(Image, { onClick: onCancel, "area-label": "close", alt: "close", src: "/images/close.svg" })] }), _jsx(FormikProvider, { value: formik, children: _jsxs("form", { onSubmit: formik.handleSubmit, noValidate: true, children: [_jsx(ModalContent, { children: _jsxs(TwoColumn, { children: [_jsx(InputPercentage, { label: "Min Percentage", name: "minPercentage" }), _jsx(InputPercentage, { label: "Max Percentage", name: "maxPercentage" })] }) }), _jsxs(ModalAction, { children: [_jsx(CancelButton, { size: "xsm", onClick: onCancel, children: "Cancel" }), _jsx(Button, { type: "submit", size: "xsm", children: "Save" })] })] }) })] }) }));
};
export default MinMaxModal;
