var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
export var HeaderContainer = styled(AppBar)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 88px;\n  box-shadow: none;\n  font-family: poppins, serif;\n  background: #023729;\n  justify-content: center;\n"], ["\n  height: 88px;\n  box-shadow: none;\n  font-family: poppins, serif;\n  background: #023729;\n  justify-content: center;\n"])));
export var NavigationWrapper = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: 10px;\n"], ["\n  margin-right: 10px;\n"])));
export var Navigation = styled(Typography)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #8d9091;\n  margin-right: 20px;\n  font-family: poppins, serif;\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 27px;\n  text-align: center;\n"], ["\n  color: #8d9091;\n  margin-right: 20px;\n  font-family: poppins, serif;\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 27px;\n  text-align: center;\n"])));
export var MenuItem = styled.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-decoration: none;\n  font-family: 'Poppins';\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.2px;\n  color: #f5f5f5;\n  margin-right: 20px;\n"], ["\n  text-decoration: none;\n  font-family: 'Poppins';\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.2px;\n  color: #f5f5f5;\n  margin-right: 20px;\n"])));
export var HeaderButton = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-decoration: none;\n  padding: 8px 20px;\n  gap: 10px;\n  height: 30px;\n  left: 1232px;\n  top: 20px;\n  background: #f6c90e;\n  border-radius: 40px;\n  margin-right: 10px;\n  color: #16473a;\n  font-family: 'Poppins';\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.2px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-decoration: none;\n  padding: 8px 20px;\n  gap: 10px;\n  height: 30px;\n  left: 1232px;\n  top: 20px;\n  background: #f6c90e;\n  border-radius: 40px;\n  margin-right: 10px;\n  color: #16473a;\n  font-family: 'Poppins';\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.2px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
