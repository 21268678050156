var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { flow } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { iso } from 'newtype-ts';
import { prismNonNegative } from 'newtype-ts/lib/NonNegative';
var isoPrice = iso();
var isoNN = iso();
var roundTwoDec = function (n) { return Math.round(n * 100) / 100; };
export var fromNumber = flow(roundTwoDec, prismNonNegative.getOption, O.map(isoPrice.wrap));
export var fromString = flow(Number.parseFloat, fromNumber);
export var toNumber = flow(isoPrice.unwrap, prismNonNegative.reverseGet);
export var unsafeFromNumber = flow(roundTwoDec, isoNN.wrap, isoPrice.wrap);
/**
 * Formats the price as a string with dollar sign
 * @param price The price to format
 * @return Given a Price of `xx.yy` it will return "$ xx.yy"
 */
export function format(price) {
    return "$ ".concat(toNumber(price).toFixed(2));
}
export var POSITIVE_INFINITY = unsafeFromNumber(Number.POSITIVE_INFINITY);
export var NEGATIVE_INFINITY = unsafeFromNumber(Number.NEGATIVE_INFINITY);
export var map = function (f) { return flow(toNumber, f, unsafeFromNumber); };
export var increasePercentage = function (percentage) {
    var isValid = Math.abs(percentage) >= 0 && Math.abs(percentage) <= 100 && Number.isInteger(percentage);
    if (!isValid) {
        throw new Error("The % (".concat(percentage, ") for Price/increasePercentage is not an int (no decimals) such as 0 <= % <= 100"));
    }
    return map(function (number) { return (number * (100 + percentage)) / 100; });
};
export var max = function (p1) {
    var ps = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        ps[_i - 1] = arguments[_i];
    }
    return __spreadArray(__spreadArray([], ps, true), [p1], false).reduce(function (prev, cur) { return (toNumber(prev) > toNumber(cur) ? prev : cur); });
};
export var min = function (p1) {
    var ps = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        ps[_i - 1] = arguments[_i];
    }
    return __spreadArray(__spreadArray([], ps, true), [p1], false).reduce(function (prev, cur) { return (toNumber(prev) > toNumber(cur) ? cur : prev); });
};
