import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export var showErrorToast = function (message) {
    return showToast(message || 'Something went wrong', 'error');
};
export var showSuccessToast = function (message) { return showToast(message, 'success'); };
export var showToast = function (message, type) {
    toast(message, {
        type: type,
    });
};
