var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { flow } from 'fp-ts/function';
import React from 'react';
import * as P from 'shared/src/typings/Price';
import Image from 'components/Shared/Image';
import { AcceptPriceImage, AcceptedPrice, SuggestedPrice, Tooltip } from '../styles';
export var DefaultColumn = function (label, key, props) {
    var _a, _b, _c;
    return {
        label: label,
        ifNotPresent: (_a = props === null || props === void 0 ? void 0 : props.ifNotPresent) !== null && _a !== void 0 ? _a : '',
        key: key,
        isSelected: (_b = props === null || props === void 0 ? void 0 : props.isSelected) !== null && _b !== void 0 ? _b : true,
        width: (_c = props === null || props === void 0 ? void 0 : props.width) !== null && _c !== void 0 ? _c : 50,
        renderFn: function (_product, value) { return value; },
    };
};
var changeRenderTo = function (renderFn) {
    return function (column) { return (__assign(__assign({}, column), { renderFn: renderFn })); };
};
var defaultColumnWith = function (render) { return flow(DefaultColumn, changeRenderTo(render)); };
var imgUrlRender = function (_product, value) { return (_jsx(Image, { src: value || 'images/logo.svg' })); };
export var ImageUrlColumn = defaultColumnWith(imgUrlRender);
var quantityRender = function (_product, value) { return value; };
export var QuantityColumn = defaultColumnWith(quantityRender);
var formatAcceptedDate = function (t) { var _a; return (_a = t === null || t === void 0 ? void 0 : t.toDate()) === null || _a === void 0 ? void 0 : _a.toLocaleDateString(); };
var formatTooltip = function (accepted, oldPrice) {
    var theDate = formatAcceptedDate(accepted.acceptedAt);
    return "The price was accepted on ".concat(theDate, " (was ").concat(P.format(oldPrice), ")");
};
var priceRender = function (product, oldPrice) {
    var _a;
    return (_jsx(_Fragment, { children: (product === null || product === void 0 ? void 0 : product.acceptedSuggestion) ? (_jsx(_Fragment, { children: _jsx(Tooltip, { title: formatTooltip(product === null || product === void 0 ? void 0 : product.acceptedSuggestion, oldPrice), arrow: true, children: _jsx(AcceptedPrice, { children: P.format((_a = product === null || product === void 0 ? void 0 : product.acceptedSuggestion) === null || _a === void 0 ? void 0 : _a.newPrice) }) }) })) : (oldPrice && P.format(oldPrice)) }));
};
export var PriceColumn = defaultColumnWith(priceRender);
var priceSuggestionRender = 
// eslint-disable-next-line react/display-name
function (handler) { return function (product) {
    var _a;
    var _b = (_a = product.latestSuggestion) !== null && _a !== void 0 ? _a : {}, id = _b.id, newPrice = _b.newPrice, acceptedAt = _b.acceptedAt;
    if (!id) {
        return '';
    }
    if (acceptedAt) {
        return (_jsx(Tooltip, { title: "Price suggestion accepted", arrow: true, children: _jsx("span", { className: "grayText", children: P.format(newPrice) }) }));
    }
    return (_jsx(_Fragment, { children: _jsxs(SuggestedPrice, { children: [P.format(newPrice), _jsx(AcceptPriceImage, { onClick: function () { return handler(product); }, src: "/images/accept.svg", alt: "Accept new suggested price", title: "Accept new suggested price" })] }) }));
}; };
export var SuggestedPriceColumn = function (label, key, props) {
    var handler = props === null || props === void 0 ? void 0 : props.acceptSuggestion;
    var columnConstructor = defaultColumnWith(priceSuggestionRender(handler));
    return columnConstructor(label, key, props);
};
