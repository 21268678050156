var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Image from 'components/Shared/Image';
import TermsLabel from '../../components/Shared/TermsLabel/TermsLabel';
export var Divider = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"])));
export var Title = styled.h4(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Gilroy', serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: ", ";\n  line-height: ", ";\n  letter-spacing: 0.2px;\n  color: ", ";\n  margin: 0;\n  text-align: center;\n\n  .coming-soon {\n    font-size: 0.7em;\n    margin-top: 10px;\n  }\n"], ["\n  font-family: 'Gilroy', serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: ", ";\n  line-height: ", ";\n  letter-spacing: 0.2px;\n  color: ", ";\n  margin: 0;\n  text-align: center;\n\n  .coming-soon {\n    font-size: 0.7em;\n    margin-top: 10px;\n  }\n"])), function (props) { return props.fontSize || '60px'; }, function (props) { return props.lineHeight || '72px'; }, function (props) { return (props.darkTheme ? '#FFFFFF' : '#16473a'); });
export var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 1350px;\n  margin: 50px auto;\n  padding: 0 15px;\n  :before {\n    content: '';\n    background: url('/images/bg-half-circle.svg') no-repeat left -10px top;\n    width: 230px;\n    height: 340px;\n    display: block;\n    position: absolute;\n    left: 0;\n    z-index: -1;\n  }\n"], ["\n  max-width: 1350px;\n  margin: 50px auto;\n  padding: 0 15px;\n  :before {\n    content: '';\n    background: url('/images/bg-half-circle.svg') no-repeat left -10px top;\n    width: 230px;\n    height: 340px;\n    display: block;\n    position: absolute;\n    left: 0;\n    z-index: -1;\n  }\n"])));
export var Line = styled(Image)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 100px;\n"], ["\n  margin-left: 100px;\n"])));
export var Description = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: ", ";\n  line-height: ", ";\n  letter-spacing: 0.2px;\n  color: #63746f;\n  margin: 0;\n"], ["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: ", ";\n  line-height: ", ";\n  letter-spacing: 0.2px;\n  color: #63746f;\n  margin: 0;\n"])), function (props) { return props.fontSize || '20px'; }, function (props) { return props.lineHeight || '35px'; });
export var SignupButton = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  text-decoration: none;\n  color: #ffffff;\n  height: 64px;\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 24px;\n  letter-spacing: 0.2px;\n  margin-top: 15px;\n  background: #16473a;\n  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.11);\n  border-radius: 40px;\n  max-width: 227px;\n  max-height: 64px;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  img {\n    margin-left: 10px;\n  }\n"], ["\n  text-decoration: none;\n  color: #ffffff;\n  height: 64px;\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 24px;\n  letter-spacing: 0.2px;\n  margin-top: 15px;\n  background: #16473a;\n  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.11);\n  border-radius: 40px;\n  max-width: 227px;\n  max-height: 64px;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  img {\n    margin-left: 10px;\n  }\n"])));
export var FeatureCard = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 15px 25px;\n  margin-bottom: 25px;\n  margin-top: 20px;\n  display: flex;\n  flex-direction: column;\n  background: #ffffff;\n  border: 1px solid rgba(238, 238, 238, 0.6);\n  box-shadow: 0 42px 50px rgba(0, 0, 0, 0.06);\n  border-radius: 14px;\n\n  :hover {\n  }\n"], ["\n  padding: 15px 25px;\n  margin-bottom: 25px;\n  margin-top: 20px;\n  display: flex;\n  flex-direction: column;\n  background: #ffffff;\n  border: 1px solid rgba(238, 238, 238, 0.6);\n  box-shadow: 0 42px 50px rgba(0, 0, 0, 0.06);\n  border-radius: 14px;\n\n  :hover {\n  }\n"])));
export var FeatureContainer = styled.section(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  margin: 0 auto;\n  max-width: 1350px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  margin: 0 auto;\n  max-width: 1350px;\n"])));
export var FeatureImageWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 20px;\n  img {\n    border-radius: 25px;\n  }\n"], ["\n  padding: 20px;\n  img {\n    border-radius: 25px;\n  }\n"])));
export var FeatureWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  max-width: 33%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  max-width: 33%;\n"])));
export var FeatureTitle = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 0 15px;\n  display: flex;\n  flex-direction: row;\n"], ["\n  padding: 0 15px;\n  display: flex;\n  flex-direction: row;\n"])));
export var FeatureContent = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  padding: 0 15px;\n  margin-top: 10px;\n"], ["\n  padding: 0 15px;\n  margin-top: 10px;\n"])));
export var Channel = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  position: relative;\n  max-width: fit-content;\n  margin: 25px;\n  .coming-soon {\n    img {\n      opacity: 0.3;\n    }\n  }\n"], ["\n  position: relative;\n  max-width: fit-content;\n  margin: 25px;\n  .coming-soon {\n    img {\n      opacity: 0.3;\n    }\n  }\n"])));
export var ChannelContent = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  margin: 0 auto;\n  display: flex;\n  bottom: 0;\n  width: auto;\n  justify-content: center;\n  align-items: center;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  margin: 0 auto;\n  display: flex;\n  bottom: 0;\n  width: auto;\n  justify-content: center;\n  align-items: center;\n"])));
export var ChannelInfo = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  align-items: center;\n  background: #ffffff;\n  border-radius: 100%;\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  height: 150px;\n  justify-content: center;\n  left: 0;\n  margin: 0 auto;\n  position: absolute;\n  right: 0;\n  top: 35px;\n  width: 150px;\n"], ["\n  align-items: center;\n  background: #ffffff;\n  border-radius: 100%;\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  height: 150px;\n  justify-content: center;\n  left: 0;\n  margin: 0 auto;\n  position: absolute;\n  right: 0;\n  top: 35px;\n  width: 150px;\n"])));
export var ChannelWrapper = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin: 50px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin: 50px;\n"])));
export var HIWContainer = styled.section(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  :before {\n    content: '';\n    background: url('/images/edgy.png');\n    height: 100px;\n    width: 100%;\n    display: block;\n    background-size: cover;\n  }\n  :after {\n    content: '';\n    background: url('/images/edgy.png');\n    height: 100px;\n    width: 100%;\n    display: block;\n    background-size: cover;\n    transform: rotate(180deg);\n  }\n"], ["\n  :before {\n    content: '';\n    background: url('/images/edgy.png');\n    height: 100px;\n    width: 100%;\n    display: block;\n    background-size: cover;\n  }\n  :after {\n    content: '';\n    background: url('/images/edgy.png');\n    height: 100px;\n    width: 100%;\n    display: block;\n    background-size: cover;\n    transform: rotate(180deg);\n  }\n"])));
export var HIWTitle = styled.h4(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-family: 'Rubik', serif;\n  font-style: normal;\n  font-weight: 300;\n  font-size: 36px;\n  line-height: 75px;\n  display: flex;\n  align-items: center;\n  text-align: center;\n  text-transform: capitalize;\n  color: #ffffff;\n  margin: 0 auto;\n  justify-content: center;\n"], ["\n  font-family: 'Rubik', serif;\n  font-style: normal;\n  font-weight: 300;\n  font-size: 36px;\n  line-height: 75px;\n  display: flex;\n  align-items: center;\n  text-align: center;\n  text-transform: capitalize;\n  color: #ffffff;\n  margin: 0 auto;\n  justify-content: center;\n"])));
export var HIWWrapper = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  background: #16473a;\n"], ["\n  background: #16473a;\n"])));
export var FooterItemsWrapper = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  max-width: 1350px;\n  padding: 0 10px;\n  margin: 0 auto;\n"], ["\n  max-width: 1350px;\n  padding: 0 10px;\n  margin: 0 auto;\n"])));
export var HIWItems = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin: 0 auto;\n  max-width: 1350px;\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin: 0 auto;\n  max-width: 1350px;\n"])));
export var HIWItemWrapper = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
export var HIWItem = styled.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  width: 369px;\n  height: 64px;\n  background: #ffffff;\n  border-radius: 12px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 20px;\n  margin: ", ";\n"], ["\n  width: 369px;\n  height: 64px;\n  background: #ffffff;\n  border-radius: 12px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 20px;\n  margin: ", ";\n"])), function (props) { return props.margin; });
export var HIWTip = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  :before {\n    content: '';\n    background: url('/images/filled-dot.svg');\n    height: 10px;\n    display: block;\n    width: 10px;\n    position: absolute;\n    right: -25px;\n  }\n  :after {\n    content: '';\n    background: url('/images/dot.svg');\n    height: 10px;\n    display: block;\n    width: 10px;\n    position: absolute;\n    left: 30px;\n    bottom: -20px;\n  }\n  position: relative;\n  width: 72px;\n  height: 100px;\n  background: rgba(255, 255, 255, 0.06);\n  border-radius: 10px;\n  img {\n    position: absolute;\n    right: ", ";\n    top: 20px;\n  }\n"], ["\n  :before {\n    content: '';\n    background: url('/images/filled-dot.svg');\n    height: 10px;\n    display: block;\n    width: 10px;\n    position: absolute;\n    right: -25px;\n  }\n  :after {\n    content: '';\n    background: url('/images/dot.svg');\n    height: 10px;\n    display: block;\n    width: 10px;\n    position: absolute;\n    left: 30px;\n    bottom: -20px;\n  }\n  position: relative;\n  width: 72px;\n  height: 100px;\n  background: rgba(255, 255, 255, 0.06);\n  border-radius: 10px;\n  img {\n    position: absolute;\n    right: ", ";\n    top: 20px;\n  }\n"])), function (props) { return props.alignRight; });
export var HIWDownArrow = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  display: flex;\n  margin: 0 auto;\n  max-width: 1450px;\n  justify-content: end;\n"], ["\n  display: flex;\n  margin: 0 auto;\n  max-width: 1450px;\n  justify-content: end;\n"])));
export var FooterWrapper = styled.footer(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  :before {\n    content: '';\n    background: url('/images/edgy.png');\n    height: 100px;\n    width: 100%;\n    display: block;\n    background-size: cover;\n  }\n  hr {\n    max-width: 1350px;\n    margin-top: 15px;\n  }\n"], ["\n  :before {\n    content: '';\n    background: url('/images/edgy.png');\n    height: 100px;\n    width: 100%;\n    display: block;\n    background-size: cover;\n  }\n  hr {\n    max-width: 1350px;\n    margin-top: 15px;\n  }\n"])));
export var FooterLinkContainer = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  max-width: 1350px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  max-width: 1350px;\n"])));
export var FooterLinks = styled.div(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var MailTo = styled.a(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  font-family: 'Rubik', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 21px;\n  display: flex;\n  align-items: center;\n  height: 24px;\n  text-decoration: none;\n  color: #ffffff;\n"], ["\n  font-family: 'Rubik', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 21px;\n  display: flex;\n  align-items: center;\n  height: 24px;\n  text-decoration: none;\n  color: #ffffff;\n"])));
export var Copyrights = styled.p(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  font-family: 'Rubik', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 41px;\n  color: #ffffff;\n"], ["\n  font-family: 'Rubik', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 41px;\n  color: #ffffff;\n"])));
export var FooterLink = styled(TermsLabel)(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  align-items: center;\n  color: #ffffff;\n  cursor: pointer;\n  display: flex;\n  font-family: 'Rubik', serif;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  height: 24px;\n  line-height: 21px;\n  margin-right: 50px;\n  text-decoration: none;\n  text-transform: capitalize;\n"], ["\n  align-items: center;\n  color: #ffffff;\n  cursor: pointer;\n  display: flex;\n  font-family: 'Rubik', serif;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  height: 24px;\n  line-height: 21px;\n  margin-right: 50px;\n  text-decoration: none;\n  text-transform: capitalize;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31;
