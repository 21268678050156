var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getNames as getCountries } from 'country-list';
import { getAuth } from 'firebase/auth';
import { FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Heading, FullWidthWrapper, StoreInfoForm, ErrorText, StoreInfoColumn, } from 'components/Auth/styles';
import { InputText, SelectInput } from 'components/Shared/FormikFields';
import { getUser, setUser } from 'lib/firebase/firestore/user';
import { userLoaderFn } from 'pages/Listing';
import { Logo, Button, Container } from './styles';
var CATEGORIES = [
    'Appliances',
    'Apps & Games',
    'Arts, Crafts, & Sewing',
    'Automotive Parts & Accessories',
    'Baby',
    'Beauty & Personal Care',
    'Books',
    'CDs & Vinyl',
    'Cell Phones & Accessories',
    'Clothing, Shoes and Jewelry',
    'Collectibles & Fine Art',
    'Computers',
    'Electronics',
    'Garden & Outdoor',
    'Grocery & Gourmet Food',
    'Handmade',
    'Health, Household & Baby Care',
    'Home & Kitchen',
    'Industrial & Scientific',
    'Kindle',
    'Luggage & Travel Gear',
    'Movies & TV',
    'Musical Instruments',
    'Office Products',
    'Pet Supplies',
    'Sports & Outdoors',
    'Tools & Home Improvement',
    'Toys & Games',
    'Video Games',
    'Other',
];
var ROLES = [
    'Assistant',
    'Business Analyst',
    'CEO',
    'Customer Service',
    'Developer',
    'Manager',
    'Marketing Manager',
    'Operations Manager',
    'Seller',
    'Other',
];
var COUNTRIES = getCountries();
var validationSchema = Yup.object().shape({
    companyName: Yup.string().required('Enter a valid company name'),
    category: Yup.mixed().oneOf(CATEGORIES).required('Select a appropriate category'),
    role: Yup.mixed().oneOf(ROLES).required('Select a appropriate role'),
    city: Yup.string().required('Enter the desired city'),
    country: Yup.mixed().oneOf(COUNTRIES).required('Select a desired country'),
    website: Yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, 'Website url is not valid'),
});
export var userInfoLoader = userLoaderFn(function (user) {
    return user.uid && getUser(user === null || user === void 0 ? void 0 : user.uid);
});
var StoreInfo = function (_a) {
    var showLogo = _a.showLogo;
    var _b = useState(), message = _b[0], setMessage = _b[1];
    var navigate = useNavigate();
    var user = getAuth().currentUser;
    var userInfo = useLoaderData() || {};
    var saveStoreInfo = function (values) {
        if (!user) {
            setMessage('Something went wrong, please try to login again');
            return;
        }
        setUser(user.uid, values)
            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                navigate(0);
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) { return setMessage(err.message); });
    };
    var formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            companyName: userInfo.companyName || '',
            category: userInfo.category || '',
            role: userInfo.role || '',
            city: userInfo.city || '',
            country: userInfo.country || '',
            website: userInfo.website || '',
        },
        onSubmit: saveStoreInfo,
    });
    return (_jsx(Container, { children: _jsxs(FullWidthWrapper, { children: [showLogo && _jsx(Logo, { alt: "Repricer", src: "images/logo.svg" }), _jsx(Heading, { textAlign: "left", children: "Store Information" }), _jsx(FormikProvider, { value: formik, children: _jsxs(StoreInfoForm, { onSubmit: formik.handleSubmit, noValidate: true, children: [_jsxs(StoreInfoColumn, { children: [_jsx(InputText, { name: "companyName", label: "Company Name" }), _jsx(InputText, { name: "website", label: "Website" })] }), _jsxs(StoreInfoColumn, { children: [_jsx(SelectInput, { name: "category", label: "Category", options: CATEGORIES }), _jsx(SelectInput, { name: "role", label: "Role", options: ROLES })] }), _jsxs(StoreInfoColumn, { children: [_jsx(InputText, { name: "city", label: "City" }), _jsx(SelectInput, { name: "country", label: "Country", options: COUNTRIES })] }), message && _jsx(ErrorText, { children: message }), _jsx(Button, { disabled: !(formik.isValid && formik.dirty), type: "submit", children: "Save" })] }) })] }) }));
};
export default StoreInfo;
