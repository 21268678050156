var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from 'components/Shared/Button';
import Image from 'components/Shared/Image';
import { BreakPoints } from 'lib/constants';
import TermsLabel from '../Shared/TermsLabel/TermsLabel';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  flex-direction: ", ";\n  ", " {\n    flex-direction: ", ";\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  flex-direction: ", ";\n  ", " {\n    flex-direction: ", ";\n  }\n"])), function (props) { return props.flexDirection; }, BreakPoints.LG, function (props) { return props.RFDirection; });
export var Logo = styled(Image)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 50px;\n  width: 160px;\n  ", " {\n    height: 31px;\n    width: 91px;\n  }\n"], ["\n  height: 50px;\n  width: 160px;\n  ", " {\n    height: 31px;\n    width: 91px;\n  }\n"])), BreakPoints.LG);
export var Heading = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'PoppinsSemiBold', serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 26px;\n  line-height: 39px;\n  color: #053f5c;\n  text-align: ", ";\n  width: ", ";\n  display: flex;\n  align-items: center;\n  gap: 25px;\n  ", " {\n    font-size: 20px;\n    line-height: 24px;\n  }\n"], ["\n  font-family: 'PoppinsSemiBold', serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 26px;\n  line-height: 39px;\n  color: #053f5c;\n  text-align: ", ";\n  width: ", ";\n  display: flex;\n  align-items: center;\n  gap: 25px;\n  ", " {\n    font-size: 20px;\n    line-height: 24px;\n  }\n"])), function (props) { var _a; return (_a = props.textAlign) !== null && _a !== void 0 ? _a : 'center'; }, function (props) { var _a; return (_a = props.width) !== null && _a !== void 0 ? _a : '100%'; }, BreakPoints.MD);
export var Text = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: #8d9091;\n  font-family: 'PoppinsMedium', serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 21px;\n  text-align: ", ";\n  width: ", ";\n  a {\n    color: #1a4fbe;\n  }\n  span {\n    color: #1a4fbe;\n  }\n  span:hover {\n    cursor: pointer;\n  }\n"], ["\n  color: #8d9091;\n  font-family: 'PoppinsMedium', serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 21px;\n  text-align: ", ";\n  width: ", ";\n  a {\n    color: #1a4fbe;\n  }\n  span {\n    color: #1a4fbe;\n  }\n  span:hover {\n    cursor: pointer;\n  }\n"])), function (props) { var _a; return (_a = props.textAlign) !== null && _a !== void 0 ? _a : 'center'; }, function (props) { var _a; return (_a = props.width) !== null && _a !== void 0 ? _a : '100%'; });
export var LeftBox = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  background: #e1e1e1;\n  width: 60%;\n  ", " {\n    width: 100%;\n    padding-top: 40px;\n    padding-bottom: 40px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  background: #e1e1e1;\n  width: 60%;\n  ", " {\n    width: 100%;\n    padding-top: 40px;\n    padding-bottom: 40px;\n  }\n"])), BreakPoints.LG);
export var Wrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  background: #e1e1e1;\n  width: 100%;\n  max-width: 600px;\n  ", " {\n    max-width: 400px;\n  }\n  ", " {\n    max-width: 300px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  background: #e1e1e1;\n  width: 100%;\n  max-width: 600px;\n  ", " {\n    max-width: 400px;\n  }\n  ", " {\n    max-width: 300px;\n  }\n"])), BreakPoints.LG, BreakPoints.MD);
export var FullWidthWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding-top: 4%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  background: #e1e1e1;\n  width: 100%;\n  gap: 10%;\n  max-width: 80%;\n  ", " {\n    max-width: 85%;\n  }\n  ", " {\n    max-width: 80%;\n    align-items: flex-start;\n  }\n"], ["\n  padding-top: 4%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  background: #e1e1e1;\n  width: 100%;\n  gap: 10%;\n  max-width: 80%;\n  ", " {\n    max-width: 85%;\n  }\n  ", " {\n    max-width: 80%;\n    align-items: flex-start;\n  }\n"])), BreakPoints.LG, BreakPoints.MD);
export var Form = styled.form(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 30px;\n  button[type='submit'] {\n    align-self: center;\n    width: 100%;\n    ", " {\n      max-width: 300px;\n      margin-top: 30px;\n      margin-bottom: 20px;\n    }\n  }\n  a {\n    font-family: 'PoppinsMedium', serif;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 42px;\n    color: #1a4fbe;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 30px;\n  button[type='submit'] {\n    align-self: center;\n    width: 100%;\n    ", " {\n      max-width: 300px;\n      margin-top: 30px;\n      margin-bottom: 20px;\n    }\n  }\n  a {\n    font-family: 'PoppinsMedium', serif;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 42px;\n    color: #1a4fbe;\n  }\n"])), BreakPoints.MD);
export var StoreInfoForm = styled(Form)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", " {\n    gap: 15px;\n  }\n"], ["\n  ", " {\n    gap: 15px;\n  }\n"])), BreakPoints.MD);
export var TermsConditionLabel = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  font-family: 'PoppinsMedium', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 21px;\n  ", " {\n    font-size: 15px;\n    line-height: 20px;\n  }\n"], ["\n  color: ", ";\n  font-family: 'PoppinsMedium', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 21px;\n  ", " {\n    font-size: 15px;\n    line-height: 20px;\n  }\n"])), function (_a) {
    var hasError = _a.hasError;
    return (hasError ? '#DC1515' : '#053f5c');
}, BreakPoints.LG);
export var TermsText = styled(TermsLabel)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: #1a4fbe;\n"], ["\n  color: #1a4fbe;\n"])));
export var RightBox = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  align-items: center;\n  background: #023729;\n  display: flex;\n  flex-direction: column;\n  flex-flow: column;\n  height: 100vh;\n  justify-content: center;\n  padding: 0 5%;\n  width: 40%;\n  > p {\n    font-family: 'PoppinsSemiBold', serif;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 26px;\n    line-height: 39px;\n    text-align: right;\n    color: #f4f1de;\n  }\n  ul {\n    padding: 0;\n    list-style: none;\n    font-family: 'PoppinsMedium', serif;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 24px;\n    color: #f4f1de;\n    li {\n      background: url('/images/ellipse.png') no-repeat;\n      padding-left: 30px;\n    }\n  }\n\n  ", " {\n    width: 100%;\n    height: auto;\n    padding: 60px 0;\n    img {\n      max-width: 320px;\n    }\n    p,\n    ul {\n      display: none;\n    }\n  }\n"], ["\n  align-items: center;\n  background: #023729;\n  display: flex;\n  flex-direction: column;\n  flex-flow: column;\n  height: 100vh;\n  justify-content: center;\n  padding: 0 5%;\n  width: 40%;\n  > p {\n    font-family: 'PoppinsSemiBold', serif;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 26px;\n    line-height: 39px;\n    text-align: right;\n    color: #f4f1de;\n  }\n  ul {\n    padding: 0;\n    list-style: none;\n    font-family: 'PoppinsMedium', serif;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 24px;\n    color: #f4f1de;\n    li {\n      background: url('/images/ellipse.png') no-repeat;\n      padding-left: 30px;\n    }\n  }\n\n  ", " {\n    width: 100%;\n    height: auto;\n    padding: 60px 0;\n    img {\n      max-width: 320px;\n    }\n    p,\n    ul {\n      display: none;\n    }\n  }\n"])), BreakPoints.LG);
export var TwoColumn = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 20px;\n  ", " {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 20px;\n  ", " {\n    flex-direction: column;\n  }\n"])), BreakPoints.MD);
export var StoreInfoColumn = styled(TwoColumn)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  gap: 3%;\n  width: ", ";\n  ", " {\n    flex-direction: ", ";\n    gap: 15px;\n    width: 100%;\n  }\n"], ["\n  gap: 3%;\n  width: ", ";\n  ", " {\n    flex-direction: ", ";\n    gap: 15px;\n    width: 100%;\n  }\n"])), function (props) { var _a; return (_a = props.width) !== null && _a !== void 0 ? _a : '100%'; }, BreakPoints.LG, function (props) { var _a; return (_a = props.flexDirection) !== null && _a !== void 0 ? _a : 'column'; });
export var MessageContainer = styled(Container)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  img {\n    margin-top: 5%;\n    ", " {\n      margin-top: 10%;\n      max-width: 320px;\n    }\n  }\n  p {\n    font-family: 'PoppinsSemiBold', serif;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 30px;\n    line-height: 45px;\n    color: #053f5c;\n    text-align: center;\n    margin: 0;\n    ", " {\n      padding: 10px;\n      font-size: 20px;\n      line-height: 30px;\n    }\n  }\n  span {\n    font-family: 'Poppins', serif;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 15px;\n    line-height: 24px;\n    color: #053f5c;\n    text-align: center;\n    a {\n      color: #429ebd;\n    }\n    a:hover {\n      cursor: pointer;\n    }\n  }\n"], ["\n  img {\n    margin-top: 5%;\n    ", " {\n      margin-top: 10%;\n      max-width: 320px;\n    }\n  }\n  p {\n    font-family: 'PoppinsSemiBold', serif;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 30px;\n    line-height: 45px;\n    color: #053f5c;\n    text-align: center;\n    margin: 0;\n    ", " {\n      padding: 10px;\n      font-size: 20px;\n      line-height: 30px;\n    }\n  }\n  span {\n    font-family: 'Poppins', serif;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 15px;\n    line-height: 24px;\n    color: #053f5c;\n    text-align: center;\n    a {\n      color: #429ebd;\n    }\n    a:hover {\n      cursor: pointer;\n    }\n  }\n"])), BreakPoints.MD, BreakPoints.MD);
export var ErrorText = styled.p(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: #dc1515;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 21px;\n  margin: 0;\n"], ["\n  color: #dc1515;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 21px;\n  margin: 0;\n"])));
export var Description = styled.p(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  color: #8d9091;\n  width: 100%;\n  a {\n    color: #1a4fbe;\n  }\n  ", " {\n    font-size: 20px;\n    line-height: 24px;\n  }\n"], ["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  color: #8d9091;\n  width: 100%;\n  a {\n    color: #1a4fbe;\n  }\n  ", " {\n    font-size: 20px;\n    line-height: 24px;\n  }\n"])), BreakPoints.MD);
export var ChannelsContainer = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  padding: 0;\n  gap: 66px;\n  margin-top: 10px;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  padding: 0;\n  gap: 66px;\n  margin-top: 10px;\n  width: 100%;\n"])));
var disabled = css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  cursor: not-allowed;\n  opacity: 0.6;\n"], ["\n  cursor: not-allowed;\n  opacity: 0.6;\n"])));
export var Channel = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  width: 200px;\n  height: 203px;\n  background-color: #f6f4f2;\n  box-shadow: 0 4px 4px rgba(141, 144, 145, 0.28);\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  gap: 25px;\n  ", ";\n  ", " {\n    width: 150px;\n    height: 150px;\n  }\n  a {\n    padding: 14px 12px;\n    text-decoration: none;\n    background: #ffffff;\n    font-family: 'PoppinsMedium', serif;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #053f5c;\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    ", " {\n      padding: 7px 6px;\n      font-size: 10px;\n      line-height: 15px;\n    }\n  }\n"], ["\n  width: 200px;\n  height: 203px;\n  background-color: #f6f4f2;\n  box-shadow: 0 4px 4px rgba(141, 144, 145, 0.28);\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  gap: 25px;\n  ", ";\n  ", " {\n    width: 150px;\n    height: 150px;\n  }\n  a {\n    padding: 14px 12px;\n    text-decoration: none;\n    background: #ffffff;\n    font-family: 'PoppinsMedium', serif;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #053f5c;\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    ", " {\n      padding: 7px 6px;\n      font-size: 10px;\n      line-height: 15px;\n    }\n  }\n"])), function (props) { return props.disable && disabled; }, BreakPoints.MD, BreakPoints.MD);
export var AddChannelForm = styled(Form)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  a {\n    text-decoration: none;\n    font-family: 'PoppinsSemiBold', serif;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    display: flex;\n    align-items: center;\n    color: #023729;\n    gap: 25px;\n    ", " {\n      font-size: 20px;\n      line-height: 24px;\n    }\n  }\n"], ["\n  a {\n    text-decoration: none;\n    font-family: 'PoppinsSemiBold', serif;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    display: flex;\n    align-items: center;\n    color: #023729;\n    gap: 25px;\n    ", " {\n      font-size: 20px;\n      line-height: 24px;\n    }\n  }\n"])), BreakPoints.MD);
export var GoogleAuthWrapper = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  margin-top: 30px;\n  :after {\n    content: '';\n    background: #c7c7c6;\n    height: 1px;\n    width: 100%;\n    position: absolute;\n    bottom: 10px;\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  margin-top: 30px;\n  :after {\n    content: '';\n    background: #c7c7c6;\n    height: 1px;\n    width: 100%;\n    position: absolute;\n    bottom: 10px;\n  }\n"])));
export var GoogleAuthText = styled.p(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  text-align: center;\n  margin: 0 auto;\n  max-width: fit-content;\n  background: #e1e1e1;\n  padding: 0 15px;\n  z-index: 2;\n  position: relative;\n"], ["\n  text-align: center;\n  margin: 0 auto;\n  max-width: fit-content;\n  background: #e1e1e1;\n  padding: 0 15px;\n  z-index: 2;\n  position: relative;\n"])));
export var GoogleButton = styled(Button)(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  background: #e1e1e1;\n  border: 1px solid #c7c7c6;\n  border-radius: 8px;\n  color: #425663;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  margin-top: 40px;\n  img {\n    margin-right: 10px;\n  }\n"], ["\n  background: #e1e1e1;\n  border: 1px solid #c7c7c6;\n  border-radius: 8px;\n  color: #425663;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  margin-top: 40px;\n  img {\n    margin-right: 10px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24;
