var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { BreakPoints } from 'lib/constants';
export var Heading = styled.h1(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: 'PoppinsSemiBold', serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 26px;\n  line-height: 39px;\n  color: #053f5c;\n  width: 100%;\n  margin: 0;\n  ", " {\n    font-size: 20px;\n    line-height: 24px;\n  }\n"], ["\n  font-family: 'PoppinsSemiBold', serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 26px;\n  line-height: 39px;\n  color: #053f5c;\n  width: 100%;\n  margin: 0;\n  ", " {\n    font-size: 20px;\n    line-height: 24px;\n  }\n"])), BreakPoints.MD);
export var BackToSignInLink = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  line-height: 80px;\n  img {\n    margin-right: 10px;\n    vertical-align: middle;\n  }\n"], ["\n  line-height: 80px;\n  img {\n    margin-right: 10px;\n    vertical-align: middle;\n  }\n"])));
export var LinkText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  color: #8d9091;\n"], ["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  color: #8d9091;\n"])));
export var SubHeading = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  color: #8d9091;\n"], ["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  color: #8d9091;\n"])));
export var Wrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: #ffffff;\n  margin: 0 auto;\n  display: block;\n  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);\n  border-radius: 8px;\n  max-width: 897px;\n  width: 100%;\n  text-align: center;\n  padding: 64px 15px;\n  button {\n    align-self: center;\n    ", " {\n      max-width: 300px;\n      margin-bottom: 20px;\n    }\n  }\n  ", " {\n    max-width: 400px;\n  }\n  ", " {\n    max-width: 300px;\n    background: transparent;\n    box-shadow: none;\n  }\n"], ["\n  background: #ffffff;\n  margin: 0 auto;\n  display: block;\n  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);\n  border-radius: 8px;\n  max-width: 897px;\n  width: 100%;\n  text-align: center;\n  padding: 64px 15px;\n  button {\n    align-self: center;\n    ", " {\n      max-width: 300px;\n      margin-bottom: 20px;\n    }\n  }\n  ", " {\n    max-width: 400px;\n  }\n  ", " {\n    max-width: 300px;\n    background: transparent;\n    box-shadow: none;\n  }\n"])), BreakPoints.MD, BreakPoints.LG, BreakPoints.MD);
export var Form = styled.form(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  max-width: 433px;\n  margin: 0 auto;\n  gap: 30px;\n  button[type='submit'] {\n    align-self: center;\n    ", " {\n      max-width: 300px;\n      margin-bottom: 20px;\n    }\n  }\n  a {\n    font-family: 'Poppins', serif;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 42px;\n    color: #1a4fbe;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  max-width: 433px;\n  margin: 0 auto;\n  gap: 30px;\n  button[type='submit'] {\n    align-self: center;\n    ", " {\n      max-width: 300px;\n      margin-bottom: 20px;\n    }\n  }\n  a {\n    font-family: 'Poppins', serif;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 42px;\n    color: #1a4fbe;\n  }\n"])), BreakPoints.MD);
export var Container = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  height: calc(100vh - 110px);\n  width: 100%;\n  align-items: center;\n  ", " {\n    height: auto;\n  }\n"], ["\n  display: flex;\n  height: calc(100vh - 110px);\n  width: 100%;\n  align-items: center;\n  ", " {\n    height: auto;\n  }\n"])), BreakPoints.MD);
export var LogoWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  max-width: 1700px;\n  margin: 25px auto;\n  padding: 0 15px;\n  ", " {\n    text-align: center;\n  }\n"], ["\n  max-width: 1700px;\n  margin: 25px auto;\n  padding: 0 15px;\n  ", " {\n    text-align: center;\n  }\n"])), BreakPoints.MD);
export var ResendButton = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  text-align: center;\n  color: #053f5c;\n  border: none;\n  background: none;\n  cursor: pointer;\n"], ["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  text-align: center;\n  color: #053f5c;\n  border: none;\n  background: none;\n  cursor: pointer;\n"])));
export var User = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  text-align: center;\n  color: #053f5c;\n"], ["\n  font-family: 'Poppins', serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  text-align: center;\n  color: #053f5c;\n"])));
export var HeroSection = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: none;\n  text-align: center;\n  ", " {\n    display: block;\n  }\n"], ["\n  display: none;\n  text-align: center;\n  ", " {\n    display: block;\n  }\n"])), BreakPoints.MD);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
