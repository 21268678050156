var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import { jsx as _jsx } from "react/jsx-runtime";
import { checkActionCode, getAuth } from 'firebase/auth';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import React from 'react';
import { Navigate, useLoaderData } from 'react-router-dom';
import { ErrorComp } from '../../components/ErrorBoundary';
var possibleModes = ['resetPassword', 'verifyEmail'];
export var isValidMode = function (maybeMode) {
    return possibleModes.find(function (mode) { return mode === maybeMode; }) != undefined;
};
var redirectUrl = (_a = {},
    _a['resetPassword'] = '/reset-password',
    _a['verifyEmail'] = '/verify-email',
    _a);
var errorRedirectUrl = (_b = {},
    _b['resetPassword'] = { redirectUrl: '/forgot-password', btnText: 'Back To Forgot Password' },
    _b['verifyEmail'] = { redirectUrl: '/verify-email', btnText: 'Back To Verify Email' },
    _b);
export var oobCodeNotPresent = { _tag: 'OobCodeNotPresent' };
export var modeNotPresent = { _tag: 'ModeNotPresent' };
export var modeIsInvalid = { _tag: 'ModeIsInvalid' };
export var checkActionFailed = function (reason, mode) {
    return { reason: reason, _tag: 'CheckActionFailed', redirectMode: mode };
};
export var oobCodeCheckFailed = { _tag: 'OobCodeCheckFailed' };
var validateOobCodeIsPresent = TE.filterOrElse(function (params) { return !!params.oobCode; }, function () { return oobCodeNotPresent; });
var validateModeIsPresent = TE.filterOrElse(function (params) { return !!params.mode; }, function () { return modeNotPresent; });
var validateModeIsValid = TE.filterOrElse(function (params) { return isValidMode(params.mode); }, function () { return modeIsInvalid; });
var tryCheckActionCode = TE.chain(function (params) {
    return pipe(TE.tryCatch(function () { return checkActionCode(getAuth(), params.oobCode); }, function (reason) { return checkActionFailed(reason, params.mode); }), TE.filterOrElse(function (checkResult) { return !!checkResult; }, function () { return oobCodeCheckFailed; }), TE.map(function () { return params; }));
});
var createTentativeAuthParams = function (params) {
    return TE.right({
        oobCode: params.get('oobCode'),
        mode: params.get('mode'),
    });
};
var makeParamsNotTentative = TE.map(function (params) { return params; });
var invalidLink = 'The link is invalid/broken. Please try again.';
/**
 * Checks the expected parameters are present, have the right values and then
 * calls the function to check the action code to ensure is valid.
 * @param request The request to parse the parameters from the URL to obtain the code and mode values or an error indicating what went wrong
 */
export var authLoader = function (_a) { return __awaiter(void 0, [_a], Promise, function (_b) {
    var url, task;
    var request = _b.request;
    return __generator(this, function (_c) {
        url = new URL(request.url);
        task = pipe(url.searchParams, createTentativeAuthParams, validateOobCodeIsPresent, validateModeIsPresent, validateModeIsValid, makeParamsNotTentative, tryCheckActionCode);
        return [2 /*return*/, task()];
    });
}); };
function isCheckedAction(error) {
    return error._tag === 'CheckActionFailed';
}
var AuthRedirection = function () {
    var maybeParams = useLoaderData();
    return pipe(maybeParams, E.map(function (authParams) {
        // there's no iterator!
        // eslint-disable-next-line react/jsx-key
        return _jsx(Navigate, { to: redirectUrl[authParams.mode], state: authParams, replace: true });
    }), E.getOrElse(function (e) {
        var _a = isCheckedAction(e)
            ? errorRedirectUrl[e.redirectMode]
            : { redirectUrl: '/dashboard', btnText: 'Back To Dashboard' }, redirectUrl = _a.redirectUrl, btnText = _a.btnText;
        return (_jsx(ErrorComp, { error: e, displayText: invalidLink, whereTo: redirectUrl, btnText: btnText }));
    }));
};
export default AuthRedirection;
