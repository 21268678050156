var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Timestamp, documentId, endBefore, getCountFromServer, getDoc, getDocs, limit as firebaseLimit, limitToLast, orderBy, query, where, setDoc, startAfter, } from 'firebase/firestore';
import { pipe } from 'fp-ts/function';
import * as NonEmptyArray from 'fp-ts/NonEmptyArray';
import { retry } from 'shared/src/retry';
import { channelsCol, listingDoc, suggestionDoc, listingsCol, getDb, channelDoc, patchListing, } from 'lib/firebase';
var visibleListingsQuery = function (userId, channel) {
    return query(listingsCol(getDb(), userId, channel), where('isParent', '==', false), where('status', '==', 'Active'));
};
/**
 * Get the user's listing info from the firestore.
 * @param uid - The firebase user's unique ID.
 * @param channel - The firebase channel.
 * @param limit - Limit the number of results.
 * @param first optional first listingId
 * @param last optional last listingId
 */
export var getVisibleListings = function (uid, channel, limit, first, last) {
    if (limit === void 0) { limit = 10; }
    var recordLimits = first
        ? [limitToLast(limit), endBefore(first)]
        : [firebaseLimit(limit)];
    return pipe(orderBy('listingId'), NonEmptyArray.of, NonEmptyArray.concat(recordLimits), NonEmptyArray.concat((last ? [startAfter(last)] : [])), function (constraints) { return query.apply(void 0, __spreadArray([visibleListingsQuery(uid, channel)], constraints, false)); }, queryCollection('latestSuggestion', 'acceptedSuggestion'));
};
export var getVisibleListingsCount = function (uid, channel) {
    return getCountFromServer(visibleListingsQuery(uid, channel)).then(function (resp) { return resp.data().count; });
};
/**
 * Get the user's channels from the db
 * @param uid the user id
 */
export var getChannels = function (uid) { return __awaiter(void 0, void 0, Promise, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, pipe(query(channelsCol(getDb(), uid), orderBy('accountNickname'), orderBy(documentId())), queryCollection())];
    });
}); };
/**
 * Return an async function which take a Documents Query and return documents data plus reference field if available
 * Regarding the reference field, for example, listing data has two reference fields, the latest suggestion and accepted suggestion, which both refer to
 * RepriceSuggestion collection.
 * @param refs: the reference field name
 */
function queryCollection() {
    var _this = this;
    var refs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        refs[_i] = arguments[_i];
    }
    return function (docsQuery) {
        return getDocs(docsQuery).then(function (snap) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, Promise.all(snap.docs.map(function (e) { return __awaiter(_this, void 0, void 0, function () {
                        var data, referencesData;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    data = e.data();
                                    return [4 /*yield*/, getReferences(data, refs)];
                                case 1:
                                    referencesData = _a.sent();
                                    return [2 /*return*/, __assign({ id: e.id }, __assign(__assign({}, data), referencesData))];
                            }
                        });
                    }); }))];
            });
        }); });
    };
}
var getReference = function (documentReference) { return __awaiter(void 0, void 0, void 0, function () {
    var res, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getDoc(documentReference)];
            case 1:
                res = _a.sent();
                data = res.data();
                if (data && documentReference.id) {
                    data.id = documentReference.id;
                }
                return [2 /*return*/, data];
        }
    });
}); };
var getReferences = function (data_1) {
    var args_1 = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args_1[_i - 1] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([data_1], args_1, true), void 0, function (data, references) {
        var res;
        if (references === void 0) { references = []; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all(references.map(function (v) { return __awaiter(void 0, void 0, void 0, function () { var _a, _b; return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _a = [v];
                                if (!(data === null || data === void 0 ? void 0 : data[v])) return [3 /*break*/, 2];
                                return [4 /*yield*/, getReference(data[v])];
                            case 1:
                                _b = _c.sent();
                                return [3 /*break*/, 3];
                            case 2:
                                _b = data[v];
                                _c.label = 3;
                            case 3: return [2 /*return*/, _a.concat([_b])];
                        }
                    }); }); }))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, Object.fromEntries(res)];
            }
        });
    });
};
/**
 * Mark the user's listing reprice status as accepted into the firestore
 * @param uid - The firebase user's unique ID.
 * @param channel - The firebase channel.
 * @param listing - The firebase listing.
 */
export var acceptRepriceSuggestion = function (uid, channel, listing) {
    if (!listing.latestSuggestion) {
        return Promise.reject("Listing doesn't have reprice suggestion");
    }
    var repriceId = listing.latestSuggestion.id;
    var patchData = {
        channelId: channel,
        sellerSku: listing.sellerSku,
        newPrice: listing.latestSuggestion.newPrice,
        productType: listing.productType,
    };
    return patchListing(patchData)
        .then(function () {
        return updateListingDoc(uid, channel, listing.id, repriceId);
    })
        .catch(function (error) {
        console.error(error);
        return Promise.reject('Error updating suggested price');
    });
};
var updateListingDoc = function (uid, channel, listingId, latestSuggestionId) {
    var acceptedAt = Timestamp.now();
    var acceptedRef = suggestionDoc(getDb(), uid, channel, listingId, latestSuggestionId);
    return Promise.all([
        retry(function () {
            return setDoc(listingDoc(getDb(), uid, channel, listingId), { acceptedSuggestion: acceptedRef }, { merge: true });
        }, 2),
        retry(function () { return setDoc(acceptedRef, { acceptedAt: acceptedAt }, { merge: true }); }, 2),
    ]);
};
/**
 * Update the min/max percentage of channel into the firestore
 * @param uid - The firebase user's unique ID.
 * @param channel - The firebase channel.
 * @param { maxPercentage, minPercentage } - The maxPercentage, minPercentage values.
 */
export var setMinMaxPercentage = function (uid, channel, _a) {
    var maxPercentage = _a.maxPercentage, minPercentage = _a.minPercentage;
    return setDoc(channelDoc(getDb(), uid, channel), { minPercentage: minPercentage, maxPercentage: maxPercentage }, { merge: true });
};
