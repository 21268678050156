import { iso } from 'newtype-ts';
import { Lens } from 'monocle-ts';
import * as Str from 'fp-ts/string';
import * as Order from 'fp-ts/Ord';
import { flow, pipe } from 'fp-ts/function';
export var MARKETPLACE_DOMAINS = [
    'United States of America',
    'Australia',
    'Belgium',
    'Brazil',
    'Canada',
    'Egypt',
    'France',
    'Germany',
    'India',
    'Italy',
    'Japan',
    'Mexico',
    'Netherlands',
    'Poland',
    'Saudi Arabia',
    'Singapore',
    'Spain',
    'Sweden',
    'Turkey',
    'United Arab Emirates',
    'United Kingdom'
];
/**
 * Order by {id}
 */
export var ordById = pipe(Str.Ord, Order.contramap(function (doc) { return doc.id; }));
export var DbCollection;
(function (DbCollection) {
    DbCollection["Channels"] = "channels";
    DbCollection["Listings"] = "listings";
    DbCollection["PricesHistory"] = "pricesHistory";
    DbCollection["RepriceSuggestions"] = "repriceSuggestions";
    DbCollection["Sales"] = "sales";
    DbCollection["Traffic"] = "traffic";
    DbCollection["Users"] = "users";
})(DbCollection || (DbCollection = {}));
export var AmazonItemName = 'itemName';
export var AmazonSellerSku = 'sellerSku';
export var AmazonImage = 'imageUrl';
/**
 * Useful lenses
 */
export var amazonListingLenses = {
    asin: Lens.fromProp()('asin'),
    sellerSku: Lens.fromProp()('sellerSku'),
    status: Lens.fromProp()('status'),
    sku: Lens.fromProp()('sellerSku'),
    isActive: Lens.fromProp()('status'),
    imageUrl: Lens.fromProp()('imageUrl'),
    productType: Lens.fromProp()('productType'),
    isParent: Lens.fromProp()('isParent'),
    skuAndAsin: Lens.fromProps()(['sellerSku', 'asin'])
};
/**
 * Predicate to check if a listing {isActive}
 */
export var isActive = flow(amazonListingLenses.isActive.get, function (status) { return status == 'Active'; });
export var idLens = function () { return Lens.fromProp()('id'); };
export var ChannelType;
(function (ChannelType) {
    ChannelType["Amazon"] = "amazon";
    ChannelType["Ebay"] = "ebay";
})(ChannelType || (ChannelType = {}));
export var amazonPricesLenses = {
    listingPrice: Lens.fromProp()('listingPrice')
};
export var salesInfoLenses = {
    revenue: Lens.fromProp()('revenue'),
    totalOrderedItems: Lens.fromProp()('totalOrderedItems'),
    totalNumberOfOrders: Lens.fromProp()('totalNumberOfOrders')
};
export var trafficInfoLenses = {
    sessions: Lens.fromProp()('sessions')
};
var isoNNI = iso();
export var nonNegIntFromUnsafe = isoNNI.wrap;
export var intFromNonNeg = isoNNI.unwrap;
