import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Channel, Heading, Description, FullWidthWrapper, ChannelsContainer, } from 'components/Auth/styles';
import Image from 'components/Shared/Image';
var AmzChannel = function (_a) {
    var disable = _a.disable, title = _a.title, to = _a.to, image = _a.image;
    return (_jsxs(Channel, { disable: disable, children: [_jsx(Image, { "area-label": title, alt: title, src: "/images/".concat(image) }), _jsxs(Link, { to: to, children: ["Go to ", title, " ", _jsx(Image, { height: "14px", src: "images/arrow-right.png" })] })] }));
};
var ChannelsPage = function () {
    return (_jsx(Container, { children: _jsxs(FullWidthWrapper, { children: [_jsx(Heading, { textAlign: "left", children: "Add Marketplace Channels" }), _jsx(Description, { children: "Connect your marketplaces/sales channels with Pro Repricer. This will let Pro Repricer get access to your product listing details and suggest you repricing suggestions. Upon your approval, this will also allow Pro Repricer to push price changes back to your marketplaces/sales channels." }), _jsx(ChannelsContainer, { children: _jsx(AmzChannel, { title: "Amazon", image: "amazon-logo.png", to: "/channels/create/amazon" }) })] }) }));
};
export default ChannelsPage;
