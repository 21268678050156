var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cryptoRandomString from 'crypto-random-string';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { getEnvOrThrow } from 'shared/src/getEnvOrThrow';
import { MARKETPLACE_DOMAINS } from 'shared/src/typings/dbmodels';
import * as Yup from 'yup';
import { AddChannelForm, TwoColumn, Container, Heading, FullWidthWrapper, ChannelsContainer, } from 'components/Auth/styles';
import Button from 'components/Shared/Button';
import { InputText, SelectInput } from 'components/Shared/FormikFields';
import Image from 'components/Shared/Image';
import { showErrorToast } from 'components/Shared/Toast';
import { SessionStorageKey } from 'lib/constants';
import { DISABLED_MARKETPLACE_DOMAINS } from 'lib/interfaces';
var validationSchema = Yup.object().shape({
    accountNickname: Yup.string().required('Enter a valid nickname'),
    marketplaceDomain: Yup.mixed()
        .oneOf(MARKETPLACE_DOMAINS.filter(function (opt) { return !DISABLED_MARKETPLACE_DOMAINS.includes(opt); }))
        .required('Select your marketplace domain'),
});
var SELLER_CENTRAL_URL = {
    'United States of America': 'https://sellercentral.amazon.com/',
};
var AmazonChannelPage = function () {
    var onSubmit = function (values) {
        var _a;
        try {
            // Generate and save state
            var state = cryptoRandomString({ length: 36 });
            sessionStorage.setItem(SessionStorageKey.oauthState, state);
            sessionStorage.setItem(SessionStorageKey.accountNickname, values.accountNickname);
            var consentUrl = new URL("".concat(SELLER_CENTRAL_URL['United States of America'], "apps/authorize/consent"));
            var applicationId = getEnvOrThrow('REACT_APP_LWA_APP_ID');
            var authDomain = getEnvOrThrow('REACT_APP_FIREBASE_AUTH_DOMAIN');
            var callbackUrl = "https://".concat(authDomain, "/channels/callback/amazon");
            consentUrl.searchParams.append('application_id', applicationId);
            consentUrl.searchParams.append('version', 'beta');
            consentUrl.searchParams.append('state', state);
            consentUrl.searchParams.append('redirect_uri', callbackUrl);
            window.open(consentUrl.toString(), '_self');
        }
        catch (error) {
            showErrorToast((_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : 'Oops ...');
        }
    };
    var formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountNickname: '',
            marketplaceDomain: 'United States of America',
        },
        onSubmit: onSubmit,
    });
    return (_jsx(Container, { children: _jsxs(FullWidthWrapper, { children: [_jsxs(Heading, { textAlign: "left", children: [_jsx(Image, { src: "/images/amazon.png" }), " Amazon Account"] }), _jsx(ChannelsContainer, { children: _jsx(FormikProvider, { value: formik, children: _jsxs(AddChannelForm, { onSubmit: formik.handleSubmit, noValidate: true, children: [_jsxs(TwoColumn, { children: [_jsx(InputText, { name: "accountNickname", label: "Account Nickname" }), _jsx(SelectInput, { name: "marketplaceDomain", label: "Marketplace Domain", options: __spreadArray([], MARKETPLACE_DOMAINS, true), disabledOptions: DISABLED_MARKETPLACE_DOMAINS })] }), _jsxs(TwoColumn, { children: [_jsxs(Link, { to: "/channels", children: [_jsx(Image, { alt: "Back", src: "/images/back.png" }), " Back"] }), _jsx(Button, { title: formik.isValid && formik.dirty
                                                ? 'Authorize Amazon channel'
                                                : 'Please enter the nickname', type: "submit", disabled: !(formik.isValid && formik.dirty), size: "sm", style: { maxWidth: '200px' }, children: "Authorize" })] })] }) }) })] }) }));
};
export default AmazonChannelPage;
