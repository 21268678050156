import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Button from 'components/Shared/Button';
import { InputText, InputPassword, CheckBox } from 'components/Shared/FormikFields';
import Image from 'components/Shared/Image';
import { TRIAL_DAYS } from 'lib/constants';
import PrivacyPolicyDialog from '../../Shared/PrivacyPolicyDialog/PrivacyPolicyDialog';
import TermsConditionsDialog from '../../Shared/TermsConditionsDialog/TermsConditionsDialog';
import GoogleSignInPopup from '../SignInWithGoogle';
import { Container, LeftBox, RightBox, Heading, Text, Wrapper, Form, ErrorText, TermsConditionLabel as Label, TermsText, } from '../styles';
var validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('This field is required'),
    password: Yup.string()
        .required('This field is required')
        .min(8, 'Password must be at least 8 characters.'),
    terms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
});
var SignUpForm = function (_a) {
    var _b;
    var onSubmit = _a.onSubmit, error = _a.error;
    var isMobile = useMediaQuery('(max-width:1023px)');
    var formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: '',
            password: '',
            terms: false,
        },
        onSubmit: onSubmit,
    });
    return (_jsx(_Fragment, { children: _jsxs(Container, { RFDirection: "column-reverse", children: [_jsx(LeftBox, { children: _jsxs(Wrapper, { children: [_jsx(Heading, { textAlign: "left", children: "Create New Account" }), !isMobile && (_jsxs(Text, { textAlign: "left", children: ["Already a Member? ", _jsx(Link, { to: "/sign-in", children: "Log in" })] })), _jsx(FormikProvider, { value: formik, children: _jsxs(Form, { onSubmit: formik.handleSubmit, noValidate: true, children: [_jsx(InputText, { label: "Email", name: "email", placeholder: "Email" }), _jsx(InputPassword, { label: "Password", name: "password" }), _jsx(CheckBox, { name: "terms", label: _jsxs(Label, { "data-testid": "terms-label", hasError: !!(formik.touched.terms && ((_b = formik.errors) === null || _b === void 0 ? void 0 : _b.terms)), children: ["Agree to", ' ', _jsx(TermsText, { text: "Terms & Conditions", children: _jsx(TermsConditionsDialog, {}) }), ' ', "and", ' ', _jsx(TermsText, { text: "Privacy Policy", children: _jsx(PrivacyPolicyDialog, {}) })] }) }), error && _jsx(ErrorText, { children: error }), _jsx(Button, { type: "submit", disabled: !(formik.isValid && formik.dirty), children: "Sign Up" })] }) }), _jsx(GoogleSignInPopup, { heading: "Or Sign up with Google", buttonText: "Sign up with Google" }), isMobile && (_jsxs(Text, { children: ["Already a Member? ", _jsx(Link, { to: "/sign-in", children: "Log in" })] }))] }) }), _jsxs(RightBox, { children: [_jsx(Image, { alt: "free trial", src: 'images/free-trial.png' }), _jsx("p", { children: "Free Trial" }), _jsxs("ul", { children: [_jsxs("li", { children: ["Valid for ", TRIAL_DAYS, " days"] }), _jsx("li", { children: "Online Support" })] })] })] }) }));
};
export default SignUpForm;
