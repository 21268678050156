var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FirebaseError } from 'firebase/app';
import React, { Component } from 'react';
import { useRouteError } from 'react-router-dom';
import { ErrorWrapper, Heading, Redirect } from './styles';
var firebaseError = function (error) { return [
    error.name,
    error.code,
    error.customData,
    error.message,
]; };
export var ErrorComp = function (error) {
    var actualError = error.error || error;
    var theError = actualError instanceof FirebaseError ? firebaseError(actualError) : actualError;
    var display = process.env.REACT_APP_ACCEPTANCE ? 'block' : 'none';
    var _a = error.displayText, displayText = _a === void 0 ? 'Something went wrong.' : _a, _b = error.whereTo, whereTo = _b === void 0 ? '/dashboard' : _b, _c = error.btnText, btnText = _c === void 0 ? 'Back To Dashboard' : _c;
    return (_jsxs(ErrorWrapper, { children: [_jsx(Heading, { fontSize: "70px", children: "OOPS!!" }), _jsx(Heading, { children: displayText }), _jsx(Redirect, { to: whereTo, children: btnText }), _jsx("div", { style: { display: display }, children: JSON.stringify(theError) })] }));
};
export function ErrorBoundaryForRoutes() {
    var error = useRouteError();
    return _jsx(ErrorComp, { error: error });
}
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            hasError: false,
        };
        return _this;
    }
    // Call api or log error or show toast can be handled here
    ErrorBoundary.prototype.componentDidCatch = function () {
        this.setState({ hasError: true });
    };
    ErrorBoundary.prototype.render = function () {
        return this.state.hasError ? _jsx(ErrorComp, {}) : this.props.children;
    };
    return ErrorBoundary;
}(Component));
export default ErrorBoundary;
