import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { AmazonImage, AmazonItemName, AmazonSellerSku, } from 'shared/src/typings/dbmodels';
import Image from 'components/Shared/Image';
import PopOverMenu from 'components/Shared/Popover';
import { Table, Cell, TableHeader, Row, Header } from '../styles';
import AddRow from './addRow';
import { DefaultColumn, ImageUrlColumn, PriceColumn, QuantityColumn, SuggestedPriceColumn, } from './columns';
var ListingTable = function (_a) {
    var _b = _a.products, products = _b === void 0 ? [] : _b, acceptSuggestion = _a.acceptSuggestion;
    var defaultColumns = [
        ImageUrlColumn('Image', AmazonImage, { width: 50 }),
        DefaultColumn('Product Name', AmazonItemName, { ifNotPresent: 'N/RA' }),
        DefaultColumn('SKU', AmazonSellerSku),
        QuantityColumn('Available', 'quantity'),
        DefaultColumn('Listing ID', 'listingId'),
        PriceColumn('Price', 'price'),
        SuggestedPriceColumn('Sug. Price', 'latestSuggestion', { acceptSuggestion: acceptSuggestion }),
    ];
    var _c = useState(defaultColumns), allColumns = _c[0], setAllColumns = _c[1];
    var selectedCols = useMemo(function () {
        return allColumns.filter(function (col) { return col.isSelected; });
    }, [allColumns]);
    var renderCol = function (col, product) {
        var _a, _b;
        var render = (_a = col.renderFn) !== null && _a !== void 0 ? _a : (function (value) { return value; });
        return (_b = render(product, product[col.key])) !== null && _b !== void 0 ? _b : col.ifNotPresent;
    };
    return (_jsx(_Fragment, { children: _jsxs(Table, { "data-testid": "listingTable", children: [_jsx(Header, { children: _jsxs("tr", { children: [selectedCols.map(function (col) { return (_jsx(TableHeader, { width: col.width, className: col.key, children: col.label }, col.key)); }), _jsx(TableHeader, { className: 'pop-over', children: _jsx(PopOverMenu, { id: "add-row-popup", icon: "/images/add-row.svg", renderMenu: function () { return _jsx(AddRow, { columns: allColumns, setAllColumns: setAllColumns }); } }) })] }) }), _jsx("tbody", { children: products === null || products === void 0 ? void 0 : products.map(function (product) { return (_jsxs(Row, { children: [selectedCols.map(function (col) { return (_jsx(Cell, { className: col.key, children: renderCol(col, product) }, col.key)); }), _jsx(Cell, { children: _jsx(Image, { src: "/images/three-dots.svg" }) }, product.listingId)] }, product === null || product === void 0 ? void 0 : product.listingId)); }) })] }) }));
};
export default ListingTable;
