var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CircularProgress from '@mui/material/CircularProgress';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Navigate, createBrowserRouter, RouterProvider, Outlet, } from 'react-router-dom';
import { auth } from 'lib/firebase';
import { getUser, startUserTrial } from 'lib/firebase/firestore/user';
import AmazonCallbackPage, { loader } from 'pages/AmazonCallback';
import AmazonChannelPage from 'pages/AmazonChannel';
import AuthRedirection, { authLoader } from 'pages/Auth';
import ChannelsPage from 'pages/Channels';
import DashboardPage, { loader as channelLoader } from 'pages/Dashboard';
import EmailNotVerified from 'pages/EmailNotVerified';
import ForgotPassword from 'pages/ForgotPassword';
import ListingPage, { loader as listingLoader } from 'pages/Listing';
import ListingWrapper, { getChannelsLoader } from 'pages/Listing/ListingWrapper';
import ResetPassword from 'pages/ResetPassword';
import SignInPage from 'pages/SignIn';
import SignUpPage from 'pages/SignUp';
import StoreInfo, { userInfoLoader } from 'pages/StoreInfo';
import VerifyEmail from 'pages/VerifyEmail';
import Welcome from 'pages/Welcome';
import { ErrorBoundaryForRoutes } from '../components/ErrorBoundary';
import Header from '../components/Header';
import { showErrorToast } from '../components/Shared/Toast';
import HomePage from '../pages/Home';
import ProtectedRoute, { Subscribed, Unregistered, Unverified, StoreInformationPending, UserInfoContext, } from './ProtectedRoute';
export var Logout = function () {
    var _a;
    (_a = getAuth()) === null || _a === void 0 ? void 0 : _a.signOut().then();
    return _jsx(Navigate, { to: '/' });
};
export function commonSignIn(navigate, signIn, onError, state) {
    return __awaiter(this, void 0, void 0, function () {
        var gotoSignIn, user, firebaseUser, _a, path, newState, e_1, msg;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    gotoSignIn = function (msg) {
                        showErrorToast(msg);
                        navigate('/sign-in');
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, , 7]);
                    return [4 /*yield*/, signIn()];
                case 2:
                    user = (_b.sent()).user;
                    if (!user) {
                        gotoSignIn('Sign in returned invalid value, please retry later.');
                    }
                    return [4 /*yield*/, getUser(user.uid)];
                case 3:
                    firebaseUser = _b.sent();
                    if (!!(firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.subscriptionEnd)) return [3 /*break*/, 5];
                    return [4 /*yield*/, startUserTrial(user.uid)];
                case 4:
                    _b.sent();
                    _b.label = 5;
                case 5:
                    _a = [
                        (state === null || state === void 0 ? void 0 : state.prevPath) || '/dashboard',
                        (state === null || state === void 0 ? void 0 : state.prevPath) && __assign(__assign({}, state), { prevPath: null }),
                    ], path = _a[0], newState = _a[1];
                    navigate(path, newState && { state: newState, replace: true });
                    return [3 /*break*/, 7];
                case 6:
                    e_1 = _b.sent();
                    msg = onError(e_1) || 'There is an error trying to Sign in, please retry later.';
                    gotoSignIn(msg);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    });
}
export var routes = [
    {
        element: _jsx(ProtectedRoute, { required: Unregistered }),
        path: '/',
        children: [
            { path: 'sign-in', element: _jsx(SignInPage, {}) },
            { path: 'sign-up', element: _jsx(SignUpPage, {}) },
            { path: 'forgot-password', element: _jsx(ForgotPassword, {}) },
            { path: 'reset-password', element: _jsx(ResetPassword, {}) },
        ],
    },
    {
        element: _jsx(ProtectedRoute, { required: Unverified }),
        path: '/',
        children: [
            { path: 'email-not-verified', element: _jsx(EmailNotVerified, {}) },
            { path: 'verify-email', element: _jsx(VerifyEmail, {}) },
        ],
    },
    {
        element: _jsx(ProtectedRoute, { required: StoreInformationPending }),
        path: '/',
        children: [
            { path: 'welcome', element: _jsx(Welcome, {}) },
            { path: 'store-info', element: _jsx(StoreInfo, { showLogo: true }) },
        ],
    },
    {
        path: '/',
        errorElement: _jsx(ErrorBoundaryForRoutes, {}),
        children: [
            { index: true, element: _jsx(HomePage, {}) },
            { path: 'sign-out', element: _jsx(Logout, {}) },
            {
                element: _jsx(AuthRedirection, {}),
                path: '/redirect-action',
                loader: authLoader,
            },
            {
                element: _jsx(UserLayout, {}),
                children: [
                    {
                        element: _jsx(ProtectedRoute, { required: Subscribed }),
                        children: [
                            { path: 'listing', element: _jsx(ListingWrapper, {}), loader: listingLoader },
                            {
                                path: 'channels',
                                children: [
                                    { path: '', element: _jsx(ChannelsPage, {}), loader: getChannelsLoader },
                                    { path: 'create/amazon', element: _jsx(AmazonChannelPage, {}) },
                                    {
                                        path: 'callback/amazon',
                                        element: _jsx(AmazonCallbackPage, {}),
                                        loader: loader,
                                    },
                                    {
                                        path: ':channelId',
                                        element: _jsx(ListingPage, {}),
                                        loader: listingLoader,
                                    },
                                ],
                            },
                            { path: 'dashboard', element: _jsx(DashboardPage, {}), loader: channelLoader },
                            { path: 'account-info', element: _jsx(StoreInfo, {}), loader: userInfoLoader },
                        ],
                    },
                ],
            },
            { path: '*', element: _jsx(Navigate, { to: "/", replace: true }) },
        ],
    },
];
function UserLayout() {
    return (_jsxs("div", { children: [_jsx(Header, {}), _jsx(Outlet, {})] }));
}
var AppRoutes = function (_a) {
    var router = _a.router, context = _a.context;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(), userInfo = _c[0], setUserInfo = _c[1];
    useEffect(function () {
        return onAuthStateChanged(auth, function () { return __awaiter(void 0, void 0, void 0, function () {
            var newUserInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!auth.currentUser) return [3 /*break*/, 2];
                        return [4 /*yield*/, getUser(auth.currentUser.uid)];
                    case 1:
                        newUserInfo = _a.sent();
                        setUserInfo(newUserInfo);
                        return [3 /*break*/, 3];
                    case 2:
                        // clear the user Info
                        setUserInfo(null);
                        _a.label = 3;
                    case 3:
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); });
    }, []);
    if (loading) {
        return _jsx(CircularProgress, {});
    }
    return (_jsx(UserInfoContext.Provider, { value: context !== null && context !== void 0 ? context : userInfo, children: _jsx(RouterProvider, { router: router !== null && router !== void 0 ? router : createBrowserRouter(routes) }) }));
};
export default AppRoutes;
