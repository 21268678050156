var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, GoogleAuthProvider } from 'firebase/auth';
import { initializeFirestore, collection, getFirestore, connectFirestoreEmulator, doc, } from 'firebase/firestore';
import { httpsCallableFromURL, getFunctions, connectFunctionsEmulator, } from 'firebase/functions';
import { getEnvOrThrow } from 'shared/src/getEnvOrThrow';
import { DbCollection, } from 'shared/src/typings/dbmodels';
// Firebase configuration
var authDomain = getEnvOrThrow('REACT_APP_FIREBASE_AUTH_DOMAIN');
var firebaseConfig = {
    apiKey: getEnvOrThrow('REACT_APP_FIREBASE_API_KEY'),
    authDomain: authDomain,
    projectId: getEnvOrThrow('REACT_APP_FIREBASE_PROJECT_ID'),
    appId: getEnvOrThrow('REACT_APP_FIREBASE_APP_ID'),
};
// Initialize Firebase
var app = initializeApp(firebaseConfig);
var auth = getAuth(app);
var db = process.env.REACT_APP_ACCEPTANCE
    ? initializeFirestore(app, { experimentalForceLongPolling: true })
    : getFirestore(app);
var functions = getFunctions(app);
var googleProvider = new GoogleAuthProvider();
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');
if (process.env.NODE_ENV === 'development') {
    // please don't change these ports, the emulator should be running
    //fnUrl in a different folder, check the README.
    connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectFunctionsEmulator(functions, 'localhost', 5001);
}
if (process.env.REACT_APP_ACCEPTANCE) {
    // please don't change these ports, the emulator should be running
    // in a different folder, check the README.
    connectAuthEmulator(auth, 'http://localhost:9095', { disableWarnings: true });
    connectFirestoreEmulator(db, 'localhost', 8085);
    connectFunctionsEmulator(functions, 'localhost', 5005);
}
export function getCallableFunction(fnUrl) {
    return httpsCallableFromURL(functions, fnUrl);
}
var createTokenUrl = getEnvOrThrow('REACT_APP_FUNCTION_URL_CREATE_AND_STORE_AMAZON_TOKEN');
var createAndStoreAmazonToken = getCallableFunction(createTokenUrl);
var patchListing = getCallableFunction(getEnvOrThrow('REACT_APP_FUNCTION_URL_ACCEPT_PRICE'));
export { auth, db, functions, googleProvider, createAndStoreAmazonToken, patchListing };
export function getDb() {
    return db;
}
export default app;
var createCollection = function (firestore, collectionName) {
    var segments = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        segments[_i - 2] = arguments[_i];
    }
    return collection.apply(void 0, __spreadArray([firestore, collectionName], segments, false));
};
var createCollectionFromRef = function (coll, path) {
    var segments = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        segments[_i - 2] = arguments[_i];
    }
    return collection.apply(void 0, __spreadArray([coll, path], segments, false));
};
export var channelsCol = function (firestore, uid) {
    return createCollection(firestore, DbCollection.Users, uid, DbCollection.Channels);
};
export var channelDoc = function (firestore, id, channelId) {
    return doc(channelsCol(firestore, id), channelId);
};
export var listingsCol = function (firestore, id, channelId) {
    return createCollectionFromRef(channelsCol(firestore, id), channelId, DbCollection.Listings);
};
export var listingDoc = function (firestore, id, channelId, listingId) { return doc(listingsCol(firestore, id, channelId), listingId); };
export var suggestionDoc = function (firestore, id, channelId, listingId, suggestionId) {
    return doc(listingDoc(firestore, id, channelId, listingId), DbCollection.RepriceSuggestions, suggestionId);
};
