import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Navigate, useLoaderData } from 'react-router-dom';
import { getChannels } from 'lib/firebase/firestore/listing';
import { userLoaderFn } from './index';
/**
 * Loads the channels from the DB
 */
export var getChannelsLoader = userLoaderFn(function (user) { return getChannels(user.uid); });
var ListingWrapper = function () {
    var channels = useLoaderData().channels;
    var url = channels.length > 0 ? "/channels/".concat(channels[0].id) : '/channels';
    return _jsx(Navigate, { to: url, replace: true });
};
export default ListingWrapper;
