var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FirebaseError } from 'firebase/app';
var INVALID_EMAIL = { 'auth/invalid-email': 'The email address is badly formatted.' };
var USER_DISABLED = {
    'auth/user-disabled': 'Your account has been disabled by an administrator',
};
export var AUTH_ERROR = __assign({ 'auth/email-already-in-use': 'The email address is already in use by another account.', 'auth/operation-not-allowed': 'The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.', 'auth/weak-password': 'The password must be 6 characters long or more.', 'auth/popup-closed-by-user': 'Signup failed because popup closed', 'auth/unauthorized-domain': 'Application not authorized to to use provider', 'auth/popup-blocked': 'Popup blocked by browser, enable the show popup', 'auth/cancelled-popup-request': 'Multiple signup requests', 'auth/account-exists-with-different-credential': 'Account already exists' }, INVALID_EMAIL);
export var AUTH_SIGN_IN_ERROR = __assign(__assign({ 'auth/user-not-found': 'There is no user record corresponding to this identifier. The user may have been deleted.', 'auth/wrong-password': 'The password is invalid or the user does not have a password.' }, INVALID_EMAIL), USER_DISABLED);
export var ACTION_CODE_ERROR = __assign({ 'auth/expired-action-code': 'Your verification link has expired.', 'auth/invalid-action-code': 'The verification link is invalid. This can happen if the link is expired, or has already been used.', 'auth/user-not-found': 'There is no user record corresponding to this identifier. The user may have been deleted.' }, USER_DISABLED);
/**
 * Searches for the error code when is a Firebase error or returns the default message if not found
 * @param err Error to check
 * @param defaultMsg Message to use in case the error is not found
 */
export function findErrorMessage(err, defaultMsg) {
    var msg = err instanceof FirebaseError
        ? ACTION_CODE_ERROR[err.code] || err.message
        : err === null || err === void 0 ? void 0 : err.message;
    return msg || defaultMsg;
}
/**
 * Searches for the error code when is a Firebase auth error or returns the default message if not found
 * @param err Error to check
 * @param defaultMsg Message to use in case the error is not found
 */
export function findAuthErrorByMessage(err, defaultMsg) {
    var msg = err instanceof FirebaseError ? AUTH_ERROR[err.code] || err.message : err === null || err === void 0 ? void 0 : err.message;
    return msg || defaultMsg;
}
