var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Toggle from 'components/Shared/Switch';
import { PopoverBox, PopoverMenuItem } from '../styles';
var AddRow = function (props) {
    var columns = props.columns, setAllColumns = props.setAllColumns;
    var toggleColumn = function (index, checked) {
        var clone = __spreadArray([], columns, true);
        clone[index].isSelected = checked;
        setAllColumns(clone);
    };
    return (_jsx(PopoverBox, { children: columns.map(function (col, index) { return (_jsxs(PopoverMenuItem, { "data-testid": 'menu' + col.key, children: [col === null || col === void 0 ? void 0 : col.label, _jsx(Toggle, { value: col.isSelected, onChange: function (e) { return toggleColumn(index, e.currentTarget.checked); } })] }, 'menu' + col.key)); }) }));
};
export default AddRow;
